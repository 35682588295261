import React from 'react'
import CheckoutSceen from '../Components/Cart/CheckoutSceen'
import BreadcrumScreen from '../Components/Breadcrum/BreadcrumScreen'
import ScrollToTop from '../Components/Main/ScrollToTop'

const Checkout = () => {
    return (
        <>
        <ScrollToTop />
            <BreadcrumScreen props=
                {{
                    page: 'checkout',
                    title: "Checkout"
                }}
            />
            <CheckoutSceen />
        </>
    )
}

export default Checkout