import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { deleteData, postData, putData } from '../../Services/Api';
import { sweetAlert } from '../../lib/sweetalert';
import { addCategory } from '../../Services/variable';
import { getCategory } from '../../Redux/Slices/categorySlice';

const useCategory = () => {

    const [catId, setCatId] = useState()
    const [category, setCategory] = useState("")
    const [image, setImage] = useState("")
    const [show, setShow] = useState(false);
    const [isloading, setIsLoading] = useState(false)
    const handleClose = () => setShow(false);

    const dispatch = useDispatch()
    const { loading, categoryList } = useSelector((state) => state.category)

    const handleAddEditCategory = () => {
        setIsLoading(true)

        if (catId) {
            const form = new FormData()
            form.append("image", image)
            form.append("id", catId)
            form.append("category", category)


            putData(addCategory, form, false)
                .then(async (res) => {
                    setIsLoading(false)
                    setShow(false)
                    await sweetAlert(res?.message, res?.status)
                    dispatch(getCategory())
                })
                .catch(async (err) => {
                    console.log(err)
                    setIsLoading(false)
                })
        }
        else {


            const form = new FormData()
            form.append("image", image)
            form.append("category", category)


            postData(addCategory, form, false)
                .then(async (res) => {
                    setShow(false)
                    setIsLoading(false)

                    setCategory("")
                    dispatch(getCategory())
                    await sweetAlert(res?.message, res?.status)
                })
                .catch(async (err) => {
                    await sweetAlert(err?.message, err?.status)
                    setIsLoading(false)
                })
        }

    }

    const handleFileChange = (event) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDelete = (id) => {
        setIsLoading(true)
        setCatId(id)
        deleteData(addCategory, id)
            .then(async (res) => {
                setIsLoading(false)
                await sweetAlert(res?.message, res?.status)
                dispatch(getCategory())
            })
            .catch(async (err) => {
                setIsLoading(false)
                await sweetAlert(err?.message, err?.status)
            })
    }

    useEffect(() => {
        dispatch(getCategory())
    }, [])

    const handleShow = () => {
        setCatId(null)
        setImage(null)
        setCategory("")
        setShow(true)
    }

    const handleEditModal = (item) => {
        setCatId(item?._id)
        setCategory(item?.category)
        setImage(item?.image)
        setShow(true)
    }

    return (
        [
            loading,
            categoryList,
            image,
            setImage,
            catId,
            setCatId,
            category,
            setCategory,
            show,
            setShow,
            handleClose,
            handleAddEditCategory,
            handleDelete,
            handleShow,
            handleEditModal,
            handleFileChange,
            isloading,
            setIsLoading
        ]
    )
}

export default useCategory