import React from 'react'
import DeliveryChargesScreen from '../Components/Delivery/DeliveryChargesScreen'
import ScrollToTop from '../Components/Main/ScrollToTop'

const DeliveryCharges = () => {
    return (

        <>
        <ScrollToTop />
            <DeliveryChargesScreen />
        </>
    )
}

export default DeliveryCharges