import React, { useEffect, useState } from 'react'
import { FaPen, FaPlus } from 'react-icons/fa'
import { useSelector } from 'react-redux';
import { sweetAlert } from '../../lib/sweetalert';
import { deleteData, getData, postData, putData } from '../../Services/Api';
import { FaTrashCan } from 'react-icons/fa6';
import { Button, Dropdown, Form, Modal, Spinner, Table } from 'react-bootstrap';
import { addCategory, addSubCategory } from '../../Services/variable';
import UseSubCategory from '../../Hooks/subCategory/UseSubCategory';

const SubCategoryScreen = () => {

    const [
        subCategoryList,
        categoryList,
        loading,
        handleCloseAddModal,
        showModal,
        setShowModal,
        mainCat,
        setMainCat,
        mainCatId,
        setMainCatId,
        subCategory,
        setSubCategory,
        subCatId,
        setSubCatId,
        allData,
        setAllData,
        handleShow,
        handleSubmit,
        handleShowEditModal,
        handleDelete,
        isLoading
    ] = UseSubCategory()



    // const { loading, categoryList } = useSelector((state) => state.category)


    // const handleCloseAddModal = () => setShowModal(false);
    // const [showModal, setShowModal] = useState(false);


    // const [mainCat, setMainCat] = useState("Select Category")
    // const [mainCatId, setMainCatId] = useState()
    // const [subCategory, setSubCategory] = useState()
    // const [subCatId, setSubCatId] = useState()

    // const [allData, setAllData] = useState([]);

    // const handleShow = () => {
    //     setMainCat("Select Category")
    //     setMainCatId(null)
    //     setSubCategory(null)
    //     setShowModal(true)
    // }

    // const handleSubmit = async (event) => {
    //     event.preventDefault()
    //     const obj = {
    //         main_category: mainCat,
    //         sub_category: subCategory,
    //         cat_id: mainCatId
    //     };

    //     if (subCatId) {

    //         putData(`${addSubCategory}/${subCatId}`, JSON.stringify(obj))
    //             .then((result) => {
    //                 // setRefresh(!refresh)
    //                 // setData("")
    //                 getSubCategory()
    //                 setShowModal(false)
    //                 sweetAlert(result?.message , result?.status)

    //             })
    //     }
    //     else {

    //         postData(addSubCategory, JSON.stringify(obj))
    //             .then((result) => {
    //                 // setRefresh(!refresh)
    //                 // setData("")
    //                 getSubCategory()
    //                 setShowModal(false)
    //                 sweetAlert(result?.message, result?.status)
    //             })
    //     }
    // }

    // const handleShowEditModal = (item) => {
    //     setSubCatId(item?._id)
    //     setMainCat(item?.main_category)
    //     setMainCatId(item?.cat_id)
    //     setSubCategory(item?.sub_category)
    //     setShowModal(true)
    // }

    // const getSubCategory = () => {
    //     getData(addSubCategory)
    //         .then((data) => {
    //             setAllData(data?.data)
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }

    // const handleDelete = async (id) => {
    //     deleteData(addSubCategory, id)
    //         .then((res) => {
    //             // alert()
    //             // setRefresh(!refresh)
    //             sweetAlert(res?.message)
    //             getSubCategory()
    //         })
    // }


    // useEffect(() => {
    //     getSubCategory()
    // }, [])

    return (
        <>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col">
                        <h4 className='fw-600'>

                            Sub Categories
                        </h4>
                    </div>

                    <div className="col-auto">
                        <button onClick={handleShow} className='btn btn-add '>
                            <FaPlus />
                        </button>
                    </div>
                </div>


                <div className="row mt-4">
                    <div className="col-md-12">
                        <Table bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Main Category</th>
                                    <th>Sub Category</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    Array.isArray(subCategoryList) && subCategoryList.length > 0 ? (

                                        subCategoryList.map((item, index) => (
                                            <>
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{item?.main_category}</td>
                                                    <td>{item?.sub_category}</td>
                                                    <td>
                                                        <div className='d-flex'>
                                                            <button className='btn btn-edit me-1'
                                                                onClick={() => handleShowEditModal(item)}
                                                            >
                                                                <FaPen />
                                                            </button>
                                                            <button className='btn btn-delete'
                                                                onClick={() => handleDelete(item?._id)}
                                                            >
                                                                <FaTrashCan />
                                                            </button>
                                                        </div>
                                                    </td>

                                                </tr>

                                            </>
                                        ))

                                    ) : (
                                        <>
                                            <tr>
                                                <td colSpan={4} className='fw-600 text-center text-capitalize'> No sub category available</td>

                                            </tr>
                                        </>
                                    )
                                }



                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>


            <Modal show={showModal} onHide={handleCloseAddModal}>
                <Modal.Header closeButton style={{ fontSize: "12px" }}>
                    <Modal.Title style={{ fontSize: "16px" }}> {subCatId ? "Edit " : " Add "} Sub Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form action="">
                        <div className="row align-items-end">


                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label className='fw-600 s-14'>
                                    Category

                                </Form.Label>

                                <Dropdown className=''>
                                    <Dropdown.Toggle className='text-start bg-transparent text-black border-secondary w-100' id="dropdown-basic">
                                        {mainCat}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='w-100'>

                                        {Array.isArray(categoryList) && (
                                            categoryList?.map((item, i) => {
                                                return (

                                                    <>
                                                        <div className="row">
                                                            <div className="col">
                                                                <Dropdown.Item value={mainCat} onClick={() => { setMainCat(item?.category); setMainCatId(item?._id) }} className=' justify-content-between'>
                                                                    {item?.category}


                                                                </Dropdown.Item>
                                                            </div>


                                                        </div >

                                                    </>
                                                )
                                            })
                                        )}

                                    </Dropdown.Menu>

                                </Dropdown>
                            </Form.Group>

                            <div className="col-12">
                                <label htmlFor="" className='fw-600 s-14 mb-2'>
                                    Sub Category
                                </label>
                                <input type="text" value={subCategory} onChange={e => setSubCategory(e.target.value)} placeholder='Sub Category Name' className='form-control' />
                            </div>

                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" size='sm' onClick={handleCloseAddModal}>

                        Close
                    </Button>
                    <Button variant="dark" className='text-white' size='sm'
                        // onClick={handleAddCategory}
                        onClick={handleSubmit}
                    >
                        {
                            isLoading ? <><Spinner size='sm' animation="border" /></> : "Save"
                        }

                    </Button>
                </Modal.Footer>
            </Modal >

        </>
    )
}

export default SubCategoryScreen