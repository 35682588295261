import React, { useState } from 'react'
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { toastAlert } from '../../lib/toastAlert'
import { postData } from '../../Services/Api'
import { signUp } from '../../Services/variable'

const SignUpScreen = () => {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [address, setAddress] = useState("")
    const [phone, setPhone] = useState("")
    const [name, setName] = useState("")

    const navigate = useNavigate()


    const handleSubmit = (e) => {
        e.preventDefault()

        const body = {
            userName: name,
            email: email,
            phone: phone,
            address: address,
            password: password,
        }

        postData(signUp, JSON.stringify(body))
            .then((data) => {
                if (data?.status === "success") {
                    toastAlert(data?.message, data?.status)
                    navigate("/login")
                }
                else {
                    toastAlert(data?.message, data?.status)
                }
            })
            .catch((err) => {
                toastAlert(err?.message, err?.status)
            })

    }

    return (

        <>

            <section className='hero-header bg-dark' >

                <div className="container pt-5">
                    <div className="row align-items-center justify-content-center" >
                        <div className="col-md-6 mt-5">
                            <div className="card overflow-hidden border-0 shadow">
                                <div className="triangle-topleft">
                                </div>


                                <h3 className='fw-600 text-center pt-3'>
                                    Sign Up
                                </h3>
                                <Form onSubmit={handleSubmit} className='p-4'>


                                    <FloatingLabel controlId="floatingname" label="User Name">
                                        <Form.Control
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            className="mb-3"
                                            type="Text" placeholder="" />
                                    </FloatingLabel>

                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Email address"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            type="email"
                                            placeholder="" />
                                    </FloatingLabel>

                                    <FloatingLabel controlId="floatingPassword" label="Password">

                                        <Form.Control
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            className="mb-3"
                                            type="password"
                                            placeholder="" />
                                    </FloatingLabel>

                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Address"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                            type="text"
                                            placeholder="" />
                                    </FloatingLabel>

                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Phone Number"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                            type="number"
                                            placeholder="" />
                                    </FloatingLabel>

                                    <Button
                                        type='submit'
                                        className=' btn w-100 btn-dark fw-600 text-warning'>
                                        <span className='fw-600 text-white'>
                                            Sign Up
                                        </span>
                                    </Button>

                                    <p className='mt-3 text-center  fw-500'>Already Have An Account?
                                        <span>
                                            <Link className='ms-2 text-decoration-none text-dark' to={"/login"}>
                                                Login
                                            </Link>
                                        </span>
                                    </p>

                                </Form>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default SignUpScreen