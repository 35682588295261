import React, { useState } from 'react'
import { FaPen, FaPlus, FaTrashCan } from "react-icons/fa6";
// import { Button, Modal } from 'bootstrap';
import { Button, Form, Modal, Spinner, Table } from 'react-bootstrap';
// import Hamdard from "../../Assets/Images/Hamdard.png";
// import { brand } from '../../Services/variable';
// import { sweetAlert } from '../../lib/sweetalert';
// import { deleteData, postData, putData } from '../../Services/Api';
// import { useDispatch, useSelector } from 'react-redux';
// import { useEffect } from 'react';
// import { getBrand } from '../../Redux/Slices/brandSlice';
import UseBrand from '../../Hooks/brand/UseBrand';
import { imageUrl } from '../../Services/variable';

const BrandsScreen = () => {

    const [
        brandList,
        loading,
        branId,
        setBranId,
        image,
        setImage,
        name,
        setName,
        show,
        setShow,
        handleClose,
        handleAddEditCategory,
        handleDelete,
        handleShow,
        handleEditModal,
        handleFileChange,
        isLoading
    ] = UseBrand()

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h2 className='mb-0 '>
                        Brands
                    </h2>
                </div>

                <div className="col-auto">
                    <button onClick={handleShow} className='btn btn-add '>
                        <FaPlus />
                    </button>
                </div>

                <div className="col-md-12 mt-4">
                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Image</th>
                                <th>Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (Array.isArray(brandList) && brandList.length > 0 ? (
                                    brandList?.map((item, i) => {
                                        return (
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td>
                                                    <img
                                                        src={item?.image}
                                                        style={{ objectFit: "contain" }} width={70} height={70} alt="" />
                                                </td>
                                                <td>{item?.brandName}</td>
                                                <td >
                                                    <div className='d-flex'>

                                                        <button className='btn btn-edit me-1' onClick={e => handleEditModal(item)}>
                                                            <FaPen />
                                                        </button>

                                                        <button disabled={isLoading} className='btn btn-delete' onClick={e => handleDelete(item._id)}>
                                                            <FaTrashCan />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : <>

                                    <tr>

                                        <td colSpan={4} className='fw-600 text-center'>No Brand Available</td>
                                    </tr>
                                </>)
                            }


                        </tbody>
                    </Table>
                </div>
            </div>


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {branId ? "Edit " : "Add "
                        } Brand

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className='modal-label'>Image</Form.Label>
                            {/* <Form.Control type="file" onChange={e => setImage(e.target.files[0])} placeholder="Enter email" /> */}
                            <Form.Control type="file" onChange={handleFileChange} placeholder="Enter email" />

                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label className='modal-label'>Brand Name</Form.Label>
                            <Form.Control type="text" value={name} onChange={e => setName(e.target.value)} placeholder="Brand Name" />
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" size='sm' onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="dark" className='text-white' size='sm' onClick={handleAddEditCategory}>
                        {isLoading ? <><Spinner size='sm' animation="border" /></> : "Save"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default BrandsScreen