import React from 'react'
import AdminLayoutScreen from "../Components/Main/AdminLayoutScreen";
const AdminLayout = () => {
    return (
        <>
            <AdminLayoutScreen />
        </>
    )
}

export default AdminLayout