import React, { useEffect, useState } from 'react'
import bg from "../../Assets/Images/bg.png";
import { Form, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { getData, postData } from '../../Services/Api';
import { deliveryCharges, order } from '../../Services/variable';
import { toastAlert } from '../../lib/toastAlert';
import { useNavigate } from 'react-router-dom';
import { removeAllCart } from '../../Redux/Slices/cartSlice';
import { sweetAlert } from '../../lib/sweetalert';


const CheckoutSceen = () => {

    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [name, setName] = useState("");
    const [products, setProducts] = useState([]);
    const [phone, setPhone] = useState([]);

    const { user } = useSelector(state => state.auth)
    const cartItems = useSelector(state => state.cart)
    const [DC, setDC] = useState()

    const dispatch = useDispatch()
    const navigate = useNavigate()


    const getCharges = () => {
        getData(deliveryCharges)
            .then((data) => {
                setDC(data?.data?.delivery)
            })
    }

    useEffect(() => {
        getCharges()
    }, [])

    useEffect(() => {


        if (user) {
            setEmail(user.user_email)
            setName(user.user_name)
            setAddress(user.user_address)
            setPhone(user.user_phone)
            setProducts(cartItems?.cart)
        }


    }, [user])




    const itemsTotal = () => {
        let price = 0;
        if (cartItems?.cart?.length > 0) {
            cartItems?.cart?.map((i) => {
                price += i.price * i.quantity
            });
        }
        return price;
    }

    const itemsQuantity = () => {
        let quantity = 0;
        if (cartItems?.cart?.length > 0) {
            cartItems?.cart?.map((i) => {
                quantity += i.quantity
            })
        }
        return quantity
    }

    const calculateTotalAmount = (cartItems, DC) => {
        let price = itemsTotal(cartItems);
        price += DC;
        return price;
    }


    const totalAmount = calculateTotalAmount(cartItems, DC);

    const placeOrder = (e) => {
        e.preventDefault();
        const body = {
            email,
            name,
            address,
            products: products?.map((val) => ({ id: val._id, quantity: val.quantity })),
            phone
        }
        postData(order, JSON.stringify(body))
            .then((response) => {

                if (response?.status === "success") {
                    (dispatch(removeAllCart()))
                    navigate("/")
                    toastAlert(response?.message, response?.status)
                }

            })
            .catch((err) => {
                console.log("err?.message,", err?.message)
                toastAlert(err?.message, err?.status)

            })
    }


    return (
        <>
            <div className="container my-5">
                <div className="row">
                    <div className="col-md-8">
                        <div className="card py-2 border-0 shadow-sm">
                            <div className="card-header border-0 bg-transparent">
                                <h5 className='fw-600 text-dark' >
                                    Detail
                                </h5>
                            </div>
                            <div className="card-body p-3">
                                <Form>


                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label className='fw-600 s-14'>Name</Form.Label>
                                        <Form.Control value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder="Name" />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label className='fw-600 s-14'>Email address</Form.Label>
                                        <Form.Control value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Enter email" />

                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label className='fw-600 s-14'>Address</Form.Label>
                                        <Form.Control value={address} onChange={(e) => setAddress(e.target.value)} type="text" placeholder="Address" />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label className='fw-600 s-14'>Phone Number</Form.Label>
                                        <Form.Control type="number" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Phone number" />
                                    </Form.Group>


                                </Form>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header bg-transparent py-3 border-0">
                                <h5 className='fw-600 text-dark'>
                                    Order Summary
                                </h5>
                            </div>

                            <div className="card-body p-3">
                                <div className="row mb-3">
                                    <div className="col  fw-600 s-12">
                                        Number of Items :
                                    </div>
                                    <div className="col-auto  fw-600 s-12">
                                        {itemsQuantity()}
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col  fw-600 s-12">
                                        Items Tota. :
                                    </div>
                                    <div className="col-auto  fw-600 s-12">
                                        {itemsTotal()}
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col  fw-600 s-12">
                                        Delivery Charges :
                                    </div>
                                    <div className="col-auto  fw-600 s-12">
                                        {DC}
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col  fw-600 s-12">
                                        Total Amount :
                                    </div>
                                    <div className="col-auto  fw-600 s-12">
                                        {/* 21254588 */}
                                        {totalAmount}
                                    </div>
                                </div>

                                <div className="row my-3">
                                    <div className="col-12">

                                        <button onClick={placeOrder} className='btn btn-dark text-white w-100 fw-700'>
                                            <span className='fw-600'>

                                                Place Order
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-8 mt-3">
                        <div className="card py-2 border-0 shadow-sm">
                            <div className="card-header border-0 bg-transparent">
                                <h5 className='fw-600 text-dark' >
                                    Order
                                </h5>
                            </div>
                            <div className="card-body p-3">
                                <Table responsive="sm">
                                    <thead className='bg-danger text-white'>
                                        <tr>
                                            <th className='s-14'>#</th>
                                            <th className='s-14'>Product</th>
                                            <th className='s-14'>Quantity</th>
                                            <th className='s-14'>Sub Total</th>

                                        </tr>
                                    </thead>
                                    <tbody>

                                        {cartItems?.cart?.map((val, index) => (
                                            <tr>
                                                <td className='s-12'>{index + 1}</td>
                                                <td>
                                                    <div className="row">
                                                        {/* <div className="col-auto p-0">

              <img src={product3} width={150} height={150} alt="" />
          </div> */}
                                                        <div className="col p-0">
                                                            <p className='mb-0'> <span className='fw-600  s-12 '> {val?.title} </span> </p>
                                                            {/* <p> <span className='fw-600 text-danger s-12'>Price : </span> <span className='fw-600 s-14'>600</span></p> */}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>

                                                    <div className="row">

                                                        <div className="col-auto">
                                                            <div className=''>
                                                                {/* <button type="" className='btn btn-sm btn-success border'>+</button> */}
                                                                <span className='s-12 px-4'>{val?.quantity}</span>
                                                                {/* <button type="" className='btn btn-danger  border'>-</button> */}

                                                            </div>
                                                        </div>
                                                    </div>


                                                </td>
                                                <td className='s-12'>{val?.price * val?.quantity}</td>
                                                {/* <td><BsFillTrash3Fill size={24} className="text-danger" /></td> */}

                                            </tr>

                                        ))}


                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CheckoutSceen