import React from 'react'
import ResetPasswordScreen from '../Components/Auth/ResetPasswordScreen'
import ScrollToTop from '../Components/Main/ScrollToTop'

const ResetPass = () => {
    return (
        <>
        <ScrollToTop />
        <ResetPasswordScreen />
        </>
    )
}

export default ResetPass