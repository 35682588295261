import React from 'react'
import OrderDetailScreen from '../Components/Order/OrderDetailScreen'
import ScrollToTop from '../Components/Main/ScrollToTop'

const OrderDetail = () => {
    return (
        <>
        <ScrollToTop />
        <OrderDetailScreen />
        </>
    )
}

export default OrderDetail