import React from 'react'
import { Card, Placeholder } from 'react-bootstrap'

const HomePlaceholder = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-3 col-6 mb-3">
                    <Card className='border-0 ' style={{ height: "200px" }}>
                        {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                        <Card.Body className='text-center mt-3'>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder  className={"rounded-circle"} style={{ width: "100px", height: "100px" }} xs={6} />
                            </Placeholder>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder xs={6} />
                            </Placeholder>
                        </Card.Body>
                    </Card>
                </div>

                <div className="col-md-3 col-6 mb-3">
                    <Card className='border-0 ' style={{ height: "200px" }}>
                        {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                        <Card.Body className='text-center mt-3'>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder  className={"rounded-circle"} style={{ width: "100px", height: "100px" }} xs={6} />
                            </Placeholder>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder xs={6} />
                            </Placeholder>
                        </Card.Body>
                    </Card>
                </div>

                <div className="col-md-3 col-6 mb-3">
                    <Card className='border-0 ' style={{ height: "200px" }}>
                        {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                        <Card.Body className='text-center mt-3'>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder  className={"rounded-circle"} style={{ width: "100px", height: "100px" }} xs={6} />
                            </Placeholder>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder xs={6} />
                            </Placeholder>
                        </Card.Body>
                    </Card>
                </div>

                <div className="col-md-3 col-6 mb-3">
                    <Card className='border-0 ' style={{ height: "200px" }}>
                        {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                        <Card.Body className='text-center mt-3'>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder  className={"rounded-circle"} style={{ width: "100px", height: "100px" }} xs={6} />
                            </Placeholder>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder xs={6} />
                            </Placeholder>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default HomePlaceholder