import React, { useEffect, useState } from 'react'
import { getData, putData } from '../../Services/Api';
import { sweetAlert } from '../../lib/sweetalert';
import { deliveryCharges } from '../../Services/variable';

const UseDelivery = () => {

    const [dc, SetDc] = useState();
    const [id, setId] = useState();
    const [isLoading, setIsLoading] = useState(false);


    const getCharges = () => {
        getData(deliveryCharges)
            .then((data) => {
                setId(data?.data?._id)
                SetDc(data?.data?.delivery)
            })
    }

    const updateDeliveryCharges = (e) => {

        e.preventDefault()
        setIsLoading(true)

        const data = {
            id: id,
            delivery: dc
        }

        putData(deliveryCharges, JSON.stringify(data))
            .then((result) => {
                setIsLoading(false)

                sweetAlert(result?.message, result?.status)
                getCharges()
            })
            .catch((err) => {
                setIsLoading(false)

                sweetAlert(err?.message, err?.status)

            })
    }

    useEffect(() => {
        getCharges()
    }, [])

    return (
        [
            dc,
            SetDc,
            id,
            setId,
            getCharges,
            updateDeliveryCharges,
            isLoading
        ]
    )
}

export default UseDelivery