import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const DashboardScreen = () => {

    const { orderList } = useSelector(state => state.order)
    const { products } = useSelector(state => state.product)
    const { categoryList } = useSelector(state => state.category)
    const { brandList } = useSelector(state => state.brand)

    const unreadObjects = orderList.filter(item => item.is_read === false);

    const unreadCount = unreadObjects.length;



    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h2>Dashboard</h2>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-md-2">
                    <Link to={"/orders"}>
                        <div className="card text-center border-0 p-3">
                            <h1 className='text-warning'>
                                {orderList?.length}
                            </h1>
                            <p className='text-warning fw-600'>
                                Total
                                <br />
                                Orders
                            </p>
                        </div>
                    </Link>
                </div>

                <div className="col-md-2">
                    <Link to={"/orders"}>
                        <div className="card text-center border-0 p-3">
                            <h1 className='text-warning'>
                                {unreadCount}
                            </h1>
                            <p className='text-warning fw-600'>
                                New
                                <br />
                                Orders
                            </p>
                        </div>
                    </Link>
                </div>

                <div className="col-md-2">
                    <Link to={"/all-products"}>
                        <div className="card text-center border-0 p-3">
                            <h1 className='text-warning'>
                                {products?.length}
                            </h1>
                            <p className='text-warning fw-600'>
                                Total
                                <br />
                                Products
                            </p>
                        </div>
                    </Link>
                </div>
                
                <div className="col-md-2">
                    <Link to={"/add-category"}>
                        <div className="card text-center border-0 p-3">
                            <h1 className='text-warning'>
                                {categoryList?.length}
                            </h1>
                            <p className='text-warning fw-600'>
                                Total
                                <br />
                                categories
                            </p>
                        </div>
                    </Link>
                </div>
                <div className="col-md-2">
                    <Link to={"/add-brands"}>
                        <div className="card text-center border-0 p-3">
                            <h1 className='text-warning'>
                                {brandList?.length}
                            </h1>
                            <p className='text-warning fw-600'>
                                Total
                                <br />
                                Brands
                            </p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default DashboardScreen