import React, { useEffect, useState } from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from "../../Assets/Images/logo.png";

import { useDispatch, useSelector } from 'react-redux';
import { removeAllCart } from '../../Redux/Slices/cartSlice';
import { logout } from '../../Redux/Slices/authSlice';
import { FaShoppingCart, FaUser, FaUserPlus } from 'react-icons/fa';

import { IoLogoWhatsapp } from "react-icons/io";
import avatar from "../../Assets/Images/avatar.png";


const Header = () => {

    const location = useLocation()
    const [isScrolled, setIsScrolled] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { token, user } = useSelector((state) => state.auth)

    const handleLogout = () => {
        dispatch(removeAllCart())
        dispatch(logout())
        navigate("/")

    }

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 2) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <>

            <div className="position-relative">

                <div className="position-fixed bottom-0 end-0">

                    <a
                        className="d-flex justify-content-center  rounded-circle m-4 p-2 align-items-center position-relative "
                        style={{ width: "64px", height: "64px", zIndex: "99999999999999999", backgroundColor: "#165916" }}
                        href="https://api.whatsapp.com/send?phone=923343333117"
                        target="_blank" >
                        <IoLogoWhatsapp className="text-white" size={50} />

                    </a>
                </div>

                <header className={`${!isScrolled ? ' header-three header-absolute sticky-header ' : 'pb-0 hero-header header-absolute header-three sticky-header sticky bg-dark'}`}
                >

                    <Navbar expand="lg" className="">
                        <Container fluid>
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="me-auto">


                                    {/* <Link className='text-decoration-none text-white' to={"/"}>Home</Link> */}


                                    <Nav.Link to={"/"} as={Link} className={`${location.pathname === "/" ? "text-active" : ''} fw-700`}  >Home</Nav.Link>

                                    <Nav.Link to={"/products"} as={Link} className={`${location.pathname === "/products" ? "text-active" : ""} fw-700`} >Products</Nav.Link>

                                    {/* <Nav.Link to={"/support"} as={Link} className={`${location.pathname === "/support" ? "text-active" : ""} fw-700`} >Support</Nav.Link> */}


                                    {
                                        token && (


                                            <Nav.Link to={"/cart"} as={Link} className={`${location.pathname === "/cart" ? "text-active" : ''} fw-700`} >

                                                <FaShoppingCart size={18} className=' me-1' />
                                                Cart
                                            </Nav.Link>

                                        )
                                    }



                                    

                                </Nav>
                            </Navbar.Collapse>

                            <Navbar.Brand to={"/"} as={Link}>
                                <img src={logo} alt="Onitit"  className="normal-logo logo-size" />

                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="ms-auto">


                                    {/* <Link className='text-decoration-none text-white' to={"/"}>Home</Link> */}


                                    {/* <Nav.Link to={"/"} as={Link} className={`${location.pathname === "/" ? "text-active" : ''} fw-700`}  >Home</Nav.Link> */}

                                    {/* <Nav.Link to={"/products"} as={Link} className={`${location.pathname === "/products" ? "text-active" : ""} fw-700`} >Products</Nav.Link> */}

                                    {/* <Nav.Link to={"/support"} as={Link} className={`${location.pathname === "/support" ? "text-active" : ""} fw-700`} >Support</Nav.Link> */}


                                    {
                                        token && (


                                            <Nav.Link to={"/cart"} as={Link} className={`${location.pathname === "/cart" ? "text-active" : ''} fw-700`} >

                                                <FaShoppingCart size={18} className=' me-1' />
                                                Cart
                                            </Nav.Link>

                                        )
                                    }



                                    {
                                        token === "" ?
                                            <>


                                                <Nav.Link as={Link} className={`${location.pathname === "/login" ? "text-active" : ''}`} to={"/login"}>
                                                    <FaUser size={15} className=' me-1' />

                                                    Login</Nav.Link>



                                                <Nav.Link as={Link} className={`${location.pathname === "/register" ? "text-active" : ''}`} to={"/register"}>
                                                    <FaUserPlus size={18} className=' me-1' />

                                                    Register</Nav.Link>

                                            </>
                                            :
                                            <>

                                                <NavDropdown className='text-active' align="start"
                                                    title={(
                                                        <div>
                                                            {user && (
                                                                <>
                                                                    <img
                                                                        src={avatar}  // Replace with the actual path to your image
                                                                        alt="User Avatar"
                                                                        className='rounded-circle me-2 border-3 border'
                                                                        style={{ width: '38px', height: '38px' }}
                                                                    />
                                                                    {user.user_name}
                                                                </>
                                                            )}
                                                        </div>
                                                    )}
                                                    // title={`${user?.user_name}`}
                                                    id="collapsible-nav-dropdown">
                                                    {/* <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                                    <NavDropdown.Item href="#action/3.2">
                                                        Another action
                                                    </NavDropdown.Item> */}
                                                    <NavDropdown.Item as={Link} to={"/my-orders"}>My Order</NavDropdown.Item>
                                                    <NavDropdown.Divider />
                                                    <NavDropdown.Item onClick={handleLogout}>
                                                        {/* <button  className='btn-pill border-0 rounded-pill px-md-4 py-2  '> */}
                                                        logout
                                                        {/* </button> */}
                                                    </NavDropdown.Item>
                                                </NavDropdown>

                                            </>
                                    }

                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </header >
            </div>

        </>
    )
}

export default Header