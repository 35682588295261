import React from 'react'
import LoginScreen from '../Components/Auth/LoginScreen'
import ScrollToTop from '../Components/Main/ScrollToTop'

const Login = () => {
    return (
        <>
        <ScrollToTop />
            <LoginScreen />
        </>
    )
}

export default Login