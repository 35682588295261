import React from 'react'
import ProductsScreen from '../Components/Products/ProductsScreen'
import BreadcrumScreen from '../Components/Breadcrum/BreadcrumScreen'
import ScrollToTop from '../Components/Main/ScrollToTop'

const AllProducts = () => {
    return (

        <>
            <ScrollToTop />
            <ProductsScreen />
        </>
    )
}

export default AllProducts