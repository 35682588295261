import React, { useEffect, useState } from 'react'
import { getData } from '../../Services/Api'
import { myOrder } from '../../Services/variable'
import { Badge, Table } from 'react-bootstrap'

const MyOrderScreen = () => {
    const [orders, setOrders] = useState([])

    const getMyOrders = () => {
        getData(myOrder)
            .then((data) => {
                setOrders(data?.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        getMyOrders()
    }, [])
    return (
        <>
            <div className="container my-5">
                <div className="row">
                    <div className="col-md-12">
                        <h1>
                            My Orders
                        </h1>

                        {Array.isArray(orders) && orders?.length > 0 ? (
                            orders?.map((item, i) => {
                                return (


                                    <div key={i} className="card my-3 border-0 shadow">
                                        <div className="card-header bg-transparent">
                                            <div className="row">
                                                <div className="col">
                                                    <h6>
                                                        Order No : {item?.orderNo}
                                                    </h6>
                                                </div>
                                                <div className="col-auto">
                                                    <h6>
                                                        <Badge bg="secondary">{item?.orderStatus}</Badge>
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-body px-1 py-0">
                                            <Table  hover className='mb-0'>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Product</th>
                                                        <th>Quantity</th>
                                                        <th>Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Array.isArray(item?.product) && item?.product?.length > 0 ? (
                                                        item?.product?.map((v, index) => {
                                                            return (
                                                                <tr key={index} className='bg-pransparent'>

                                                                    <td>{index + 1}</td>
                                                                    <td>{v?.title}</td>
                                                                    <td>{v?.quantity}</td>
                                                                    <td>{v?.price}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    ) : (
                                                        ""
                                                    )}

                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                )
                            })
                        ) : (<>
                            <h6 className='fw-600 text-center'>
                                No Order Found
                            </h6>
                        </>)}

                    </div>
                </div>
            </div>
        </>
    )
}

export default MyOrderScreen