import React from 'react'
import SubCategoryScreen from '../Components/Category/SubCategoryScreen'
import ScrollToTop from '../Components/Main/ScrollToTop'

const AddSubCat = () => {
    return (
        <>
        <ScrollToTop />
            <SubCategoryScreen />
        </>
    )
}

export default AddSubCat