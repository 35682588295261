import React from 'react'
import AddCategoryScreen from '../Components/Category/AddCategoryScreen'
import ScrollToTop from '../Components/Main/ScrollToTop'

const AddCategory = () => {
    return (
        <>
        <ScrollToTop />
            <AddCategoryScreen />
        </>
    )
}

export default AddCategory