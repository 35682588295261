import React from 'react'
import { Button, Dropdown, Form, Spinner } from 'react-bootstrap'
import UseProduct from '../../Hooks/product/UseProduct'

const CreateProductsScreen = () => {

    const [
        products,
        slug,
        categoryList,
        subCategoryList,
        brandList,
        id,
        setId,
        image,
        setImage,
        name,
        setName,
        price,
        setPrice,
        brand,
        setBrand,
        brandId,
        setBrandId,
        category,
        setCategory,
        categoryId,
        setCategoryId,
        subCat,
        setSubCat,
        subCatId,
        setSubCatId,
        description,
        setDescription,
        handleAddProducts,
        getProduct,
        handleDelete,
        handleFileChange,
        isLoading,
        delLoading
    ] = UseProduct()


    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h2 className='mb-0 '>

                            {slug ? "Edit " : "Add "}
                            Product
                        </h2>
                    </div>

                    <div className="col-md-12 my-5">
                        <Form onSubmit={handleAddProducts}>
                            <div className="row">

                                <div className="col-md-6">
                                    <Form.Group className="mb-3" controlId="">
                                        <Form.Label className='modal-label'>Image</Form.Label>
                                        <Form.Control type="file" onChange={handleFileChange} />
                                    </Form.Group>
                                </div>

                                <div className="col-md-6">
                                    <Form.Group className="mb-3" controlId="">
                                        <Form.Label className='modal-label'>Name</Form.Label>
                                        <Form.Control value={name} onChange={e => setName(e.target.value)} type="text" placeholder="Enter Product Name" />
                                    </Form.Group>
                                </div>

                                <div className="col-md-6">
                                    <Form.Group className="mb-3" controlId="">
                                        <Form.Label className='modal-label'>Price</Form.Label>
                                        <Form.Control value={price} onChange={e => setPrice(e.target.value)} type="number" placeholder="Enter Product Price" />
                                    </Form.Group>
                                </div>

                                <div className="col-md-6">
                                    <Form.Group className="mb-3">
                                        <Form.Label className='modal-label'>
                                            Brand
                                        </Form.Label>

                                        <Dropdown className='mb-3'>
                                            <Dropdown.Toggle className='text-start bg-white text-black border-0 w-100' id="dropdown-basic">
                                                {brand}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='w-100'>

                                                {Array.isArray(brandList) && (
                                                    brandList?.map((item, i) => {
                                                        return (

                                                            <>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <Dropdown.Item value={brand} onClick={() => { setBrand(item?.brandName); setBrandId(item?._id) }} className=' justify-content-between'>
                                                                            {item?.brandName}


                                                                        </Dropdown.Item>
                                                                    </div>


                                                                </div >

                                                            </>
                                                        )
                                                    })
                                                )}

                                            </Dropdown.Menu>

                                        </Dropdown>
                                    </Form.Group>
                                </div>

                                <div className="col-md-6">
                                    <Form.Group className="mb-3">
                                        <Form.Label className='modal-label'>
                                            Category

                                        </Form.Label>

                                        <Dropdown className='mb-3'>
                                            <Dropdown.Toggle className='text-start bg-white text-black border-0 w-100' id="dropdown-basic">
                                                {category}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='w-100'>

                                                {Array.isArray(categoryList) && (
                                                    categoryList?.map((item, i) => {
                                                        return (

                                                            <>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <Dropdown.Item value={category} onClick={() => { setCategory(item?.category); setCategoryId(item?._id) }} className=' justify-content-between'>
                                                                            {item?.category}


                                                                        </Dropdown.Item>
                                                                    </div>


                                                                </div >

                                                            </>
                                                        )
                                                    })
                                                )}

                                            </Dropdown.Menu>

                                        </Dropdown>
                                    </Form.Group>
                                </div>

                                <div className="col-md-6">
                                    <Form.Group className="mb-3">
                                        <Form.Label className='modal-label'>
                                            Sub Category
                                        </Form.Label>

                                        <Dropdown className='mb-3'>
                                            <Dropdown.Toggle className='text-start bg-white text-black border-0 w-100' id="dropdown-basic">
                                                {subCat}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='w-100'>

                                                {Array.isArray(subCategoryList) && subCategoryList.length > 0 ? (
                                                    subCategoryList?.map((item, i) => (
                                                        (categoryId === item?.cat_id) ?
                                                            (
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <Dropdown.Item value={subCat} onClick={() => { setSubCat(item?.sub_category); setSubCatId(item?._id) }} className=' justify-content-between'>
                                                                                {item?.sub_category}
                                                                            </Dropdown.Item>
                                                                        </div>
                                                                    </div >
                                                                </>
                                                            )
                                                            :
                                                            <>

                                                            </>

                                                    ))
                                                ) :
                                                    <>
                                                        <Dropdown.Item className=' justify-content-between'>
                                                            No Sub Category Available
                                                        </Dropdown.Item>
                                                    </>}

                                            </Dropdown.Menu>

                                        </Dropdown>
                                    </Form.Group>
                                </div>

                                <div className="col-md-12">
                                    <Form.Group className="mb-3">
                                        <Form.Label className='modal-label'>Description</Form.Label>
                                        <Form.Control value={description} onChange={e => setDescription(e.target.value)} type="text" as={"textarea"} rows={4} placeholder="Enter Product Description" />
                                    </Form.Group>
                                </div>

                                <div className='text-end'>
                                    <Button variant="dark" className='text-white' type="submit">
                                        {isLoading ? <><Spinner size='sm' animation="border" /></> : "Save"}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>

                </div>
            </div>
        </>
    )
}

export default CreateProductsScreen