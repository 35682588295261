import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';

const BreadcrumScreen = ({props}) => {
    return (
        <>
            <section className=' bg bg-dark position-relative hero-header'>

                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className='text-white mb-0 text-center s-50 pt-150  fw-700'>
                                {/* Our Prodduct */}
                                {props.title}

                            </p>

                            <div className="col-auto">
                                <Breadcrumb >
                                    <Breadcrumb.Item as={Link} >
                                        <Link to={"/"} className='fw-600 text-white text-decoration-none'>
                                            Home
                                        </Link>
                                    </Breadcrumb.Item>

                                    <Breadcrumb.Item active>

                                        <Link to={"/"} className='fw-600 text-white text-decoration-none'>
                                            {props.page}
                                            {/* Product */}
                                        </Link>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>





            </section>
        </>
    )
}

export default BreadcrumScreen