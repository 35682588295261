import React, { useEffect, useState } from 'react'
import { getData, putData } from '../../Services/Api';
import { deliveryCharges } from '../../Services/variable';
import { sweetAlert } from '../../lib/sweetalert';
import UseDelivery from '../../Hooks/delivery/UseDelivery';
import { Spinner } from 'react-bootstrap';

const DeliveryChargesScreen = () => {

    const
        [
            dc,
            SetDc,
            id,
            setId,
            getCharges,
            updateDeliveryCharges,
            isLoading
        ] = UseDelivery()

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h2 className='mb-0 '>
                            Delivery Charges
                        </h2>
                    </div>

                    <div className="col-md-12 mt-5 delivery">
                        <div className="card p-2 py-4 border-0 rounded-0" >

                            <div className="row justify-content-center">

                                <div className="col-md-10">

                                    <label >
                                        Add Delivery Charges
                                    </label>
                                    <div className=" ">

                                        <div className="row border border-2 rounded-2 overflow-hidden">

                                            <div className="col-md-10 pe-0">
                                                <input type="number" className=' w-100 form-control border-0' value={dc} onChange={e => SetDc(e.target.value)} />
                                            </div>
                                            <div className="col-2 px-0">
                                                <button onClick={updateDeliveryCharges} className='btn w-100 btn-dark text-white rounded-0'>
                                                    {
                                                        isLoading ? <><Spinner size='sm' animation="border" /></> : "Update"
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </>
    )
}

export default DeliveryChargesScreen