import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DashboardScreen from '../Components/AdminHome/DashboardScreen';
import BreadcrumScreen from '../Components/Breadcrum/BreadcrumScreen';
import AddBrand from '../Pages/AddBrand';
import AddCategory from '../Pages/AddCategory';
import AddProduct from '../Pages/AddProduct';
import AddSubCat from '../Pages/AddSubCat';
import AdminLayout from '../Pages/AdminLayout';
import AllOrders from '../Pages/AllOrders';
import AllProducts from '../Pages/AllProducts';
import Cart from '../Pages/Cart';
import Checkout from '../Pages/Checkout';
import CreateProduct from '../Pages/CreateProduct';
import DeliveryCharges from '../Pages/DeliveryCharges';
import Home from '../Pages/Home';
import Layout from '../Pages/Layout';
import Login from '../Pages/Login';
import MyOrder from '../Pages/MyOrder';
import OrderDetail from '../Pages/OrderDetail';
import Product from '../Pages/Product';
import ProductDetail from '../Pages/ProductDetail';
import SignUp from '../Pages/SignUp';
import ResetPass from '../Pages/ResetPass';

const AppRouting = () => {

    const { token, user } = useSelector((state) => state.auth)

    const checkToken = () => {

        if (token && token === "") {
            return true
        }
        else {
            return false
        }
    }

    useEffect(() => {
        checkToken()

    }, [token, user])



    // const token = "user"


    return (
        <>


            <BrowserRouter>
                <>
                    {!user ? <>
                        <Routes>
                            <Route path="/" element={<Layout />}>
                                <Route index element={<Home />} />
                                <Route path='login' element={<Login />} />
                                <Route path='register' element={<SignUp />} />
                                <Route path='product-detail/:slug' element={<ProductDetail />} />
                                <Route path='products' element={<AllProducts />} />
                                {/* <Route path='product' element={<Product />} /> */}
                                {/* <Route path='cart' element={<Cart />} /> */}
                                {/* <Route path='breadcrum' element={<BreadcrumScreen />} /> */}
                                {/* <Route path='checkout' element={<Checkout />} /> */}

                            </Route>
                        </Routes>

                    </> : <>
                        {
                            user?.user_type === "user" ?
                                <>
                                    <Routes>
                                        <Route path="/" element={<Layout />}>
                                            <Route index element={<Home />} />
                                            <Route path='product' element={<Product />} />
                                            <Route path='login' element={<Login />} />
                                            <Route path='register' element={<SignUp />} />
                                            <Route path='cart' element={<Cart />} />
                                            <Route path='products' element={<AllProducts />} />
                                            <Route path='product-detail/:slug' element={<ProductDetail />} />
                                            <Route path='breadcrum' element={<BreadcrumScreen />} />
                                            <Route path='checkout' element={<Checkout />} />
                                            <Route path='my-orders' element={<MyOrder />} />

                                        </Route>
                                    </Routes>

                                </>
                                :

                                <>
                                    <Routes>
                                        <Route path="/" element={<AdminLayout />}>
                                            <Route index element={<DashboardScreen />} />
                                            <Route path='add-category' element={<AddCategory />} />
                                            <Route path='sub-category' element={<AddSubCat />} />
                                            <Route path='add-brands' element={<AddBrand />} />
                                            <Route path='orders' element={<AllOrders />} />
                                            <Route path='orders-detail/:id' element={<OrderDetail />} />
                                            <Route path='all-products' element={<AddProduct />} />
                                            <Route path='delivery-charges' element={<DeliveryCharges />} />
                                            <Route path='change-password' element={<ResetPass />} />
                                            {/* <Route path='add-product' element={<CreateProduct />} /> */}
                                            {/* <Route path='edit-product' element={<EditProduct />} /> */}
                                            {/* <Route path="/add-product/:id">
                                        <Route path=":id" element={<AddProduct />} />
                                        <Route path="" element={<AddProduct />} />
                                    </Route> */}

                                            <Route path="/add-edit-product">
                                                <Route index element={<CreateProduct />} />
                                                <Route path=":slug" element={<CreateProduct />} />
                                            </Route>
                                        </Route>
                                    </Routes>

                                </>
                        }

                    </>}
                </>




            </BrowserRouter>
        </>
    )
}

export default AppRouting