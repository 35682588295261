import React from 'react'
import BreadcrumScreen from '../Components/Breadcrum/BreadcrumScreen'
import CartScreen from '../Components/Cart/CartScreen'
import ScrollToTop from '../Components/Main/ScrollToTop'

const Cart = () => {
  return (
    <>
    <ScrollToTop />
      <BreadcrumScreen props=
        {{ page: 'Cart', title: 'Cart' }}
      />
      <CartScreen />
    </>
  )
}

export default Cart