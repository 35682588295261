import React from 'react'
import AllOrdersScreen from '../Components/Order/AllOrdersScreen'
import ScrollToTop from '../Components/Main/ScrollToTop'

const AllOrders = () => {
    return (
        <>
        <ScrollToTop />
            <AllOrdersScreen />
        </>
    )
}

export default AllOrders