import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addWithQuantity } from '../../Redux/Slices/cartSlice';
import { getRecord } from '../../Services/Api';
import { imageUrl, product } from '../../Services/variable';
import { toastAlert } from '../../lib/toastAlert';
import BreadcrumScreen from '../Breadcrum/BreadcrumScreen';


const ProductDetailScreen = () => {

    const [data, setData] = useState(null)
    const [itemQuantity, setItemQuantity] = useState(1)
    const { slug } = useParams();

    const { token } = useSelector((state) => state.auth)


    const dispatch = useDispatch()

    const handleIncreament = () => {
        setItemQuantity(itemQuantity + 1)
    }

    const handleDecreament = () => {
        if (itemQuantity > 0) {

            setItemQuantity(itemQuantity - 1)
        }
    }

    console.log("data====", data)

    const fun = () => {
        if (token) {

            const obj = {
                _id: data._id,
                title: data.title,
                price: data.price,
                description: data.description,
                category: data.category,
                image: data.image,
                isActive: data.isActive,
                quantity: itemQuantity
            }

            dispatch(addWithQuantity(obj))
            toastAlert("Added To Cart", "success")
            console.log("obj====", obj)
        }
        else {
            toastAlert("To Add Any Item You Need To Login ", "error")

        }

    }


    const getProduct = (slug) => {
        getRecord(product, slug)
            .then((data) => {
                setData(data?.data)
            })
            .catch((err) => {
                console.log("err", err)
            })

    }

    useEffect(() => {
        getProduct(slug)
    }, [])

    console.log("first data ", data)
    return (
        <>
            {data && (

                <BreadcrumScreen props=
                    {{ page: data?.title, }}
                />
            )}

            {data && (
                <>
                    <div className="container my-5">
                        <div className="row">

                            <div className="col-md-2 d-none">

                                <div className="row">
                                    <div className="col-md-12 col-4">
                                        <div className="card p-1 mb-2 shadow-sm border-0">
                                            <img src={`${imageUrl}${data?.image}`} width={"100%"} alt="" />

                                        </div>
                                    </div>
                                    <div className="col-md-12 col-4">
                                        <div className="card p-1 mb-2 shadow-sm border-0">
                                            <img src={data?.image} width={"100%"} alt="" />

                                        </div>
                                    </div>
                                    <div className="col-md-12 col-4">
                                        <div className="card p-1 mb-2 shadow-sm border-0" >
                                            <img src={data?.image} width={"100%"} alt="" />

                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="col-md-5">
                                <div className="card p-1 border-0">

                                    <img src={data?.image} width={"100%"} alt="" />
                                </div>
                            </div>

                            <div className="col-md-5 mt-5 mt-md-0">
                                <h1>
                                    {data?.title}
                                </h1>

                                <p className='mt-4'>
                                    {data?.description}
                                    {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate exercitationem quia asperiores minima odit, */}
                                    {/* dolores sint fugiat facilis iure! Soluta exercitationem non explicabo tenetur praesentium expedita. Accusantium officiis sit enim? */}
                                </p>

                                <p className='fw-600 mt-4'>
                                    Price
                                </p>

                                <p className='fw-600 text-warning'>
                                    <span className='s-18 '>Rs:</span>
                                    <span className='s-18 '>
                                        {data?.price}
                                    </span>
                                </p>

                                {/* <div className="col-auto">
                            <div className='border '>
                                <button type="" className=' bg-transparent border'>-</button>
                                <span className='px-4 s-18'>3</span>
                                <button type="" className=' bg-transparent border'>+</button>

                            </div>
                        </div> */}

                                <div className="row align-items-center mt-4">
                                    <div className="col-auto">
                                        <div className=' rounded'>
                                            <button type=""
                                                onClick={handleDecreament}

                                                className='btn bg-danger text-white'>-</button>
                                            <span className='px-4 s-18'>
                                                {itemQuantity}
                                            </span>
                                            <button type=""
                                                onClick={handleIncreament}


                                                className='btn bg-dark text-warning fw-700'>
                                                <span className='text-white'>
                                                    +
                                                </span>
                                            </button>

                                        </div>
                                    </div>

                                    <div className="col-auto">
                                        <button
                                            onClick={() => fun()}

                                            className='btn btn-dark text-uppercase fw-700'>
                                            <span className='fw-600 text-white'>
                                                Add to cart
                                            </span>
                                        </button>
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>

                </>
            )}

        </>
    )
}

export default ProductDetailScreen