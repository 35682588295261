import React, { useEffect, useState } from 'react';
import { A11y, Autoplay, EffectFade, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const HomeBanner = () => {

  const [category, setCategory] = useState(null)
  const navigate = useNavigate()

  const handleCategory = (item) => {
    setCategory(item);
  };

  useEffect(() => {
    if (category !== null) {
      const query = `?brand_id=&category_id=${category}`;
      navigate(`/products${query}`);
    }
  }, [category]);

  return (
    <>


      {/* <div className="container-fluid" > */}


      <Swiper

        // install Swiper modules
        modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y, EffectFade]}
        spaceBetween={20}
        loop={true}
        autoplay={{
          delay: 3000
        }}
        speed={3000}
        // effect='fade' // Use fade effect
        // fadeEffect={
        //   crossFade = true // Enable cross-fade transition between slides
        // }
        // effect="fade"
        // fadeEffect={{ crossFade: true }}
        // slidesPerView={3}
        // spaceBetween={10}
        // slidesPerView={3}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },

          // when window width is >= 576px
          576: {
            slidesPerView: 1,
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 1,
          },
          // when window width is >= 1024px
          1024: {
            slidesPerView: 1,
          },
          1280: {
            slidesPerGroup: 1,
            slidesPerView: 1,
          },
        }}
        // navigation
        pagination={{ clickable: true }}
        // scrollbar={{ draggable: true }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
      >


        <div className="row">
          <SwiperSlide >
            <section className='bg-homeo'>
              <div className="overlay h-100">
                <div className="row h-100 align-items-center justify-content-center">
                  <div className="col-md-6">
                    <div onClick={(e) => handleCategory("Homeo")}>
                      <h1 className='fw-700 mb-0 text-center text-capitalize text-white'>
                        Homeopathy
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </SwiperSlide>
          <SwiperSlide >
            <section className='bg-herbal'>
              <div className="overlay h-100">
                <div className="row h-100 align-items-center justify-content-center">
                  <div className="col-md-6">
                    <div onClick={(e) => handleCategory("Herbal")}>
                      <h1 className='fw-700 mb-0 text-center text-capitalize text-white'>
                        Herbal
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </SwiperSlide>
          <SwiperSlide >
            <section className='bg-neutra'>
              <div className="overlay h-100">
                <div className="row h-100 align-items-center justify-content-center">
                  <div className="col-md-6">
                    <div onClick={(e) => handleCategory("Neutrasutical")}>
                      <h1 className='fw-700 text-center text-capitalize text-white'>
                        neutrasutical
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </SwiperSlide>

        </div>





      </Swiper >

      {/* </div> */}

      < section className=' bg bg-dark position-relative hero-header rounded-0 rounded-top-0 d-none' >



        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p className='text-white mb-0 text-center s-50 pt-150 pb-5 fw-700'>
                BIN ABDULLAH
              </p>
              <span>

              </span>
            </div>
          </div>
        </div>


      </section >
    </>
  )
}

export default HomeBanner