
const token = localStorage.getItem("access_token")
function getHeaders() {
    return `Bearer ${localStorage.getItem("access_token")}`
}


// Get Data


export const getData = async (url) => {
    try {
        const res = await fetch(url, {
            method: 'GET',
            headers: new Headers({
                'Accept': 'application/json',
                'Authorization': getHeaders(),

            })
        });
        return await res.json();
    }
    catch (error) {
        throw new Error("error ")
    }
}

export const getRecord = async (url, id) => {
    try {
        const res = await fetch(`${url}/${id}`, {
            method: 'GET',
            headers: new Headers({
                'Accept': 'application/json',
            })
        });
        return await res.json();
    }
    catch (error) {
        throw new Error("error ")
    }
}


// function to make POST request 

export const postData = async (url, data, header = true) => {
    try {
        let apiReq = {
            method: 'POST',
            body: data,
        }
        if (header) {
            apiReq.headers = new Headers({
                'Content-Type': 'application/json',
                'Authorization': getHeaders(),
            });

        }
        else {
            apiReq.headers = new Headers({
                'Authorization': getHeaders(),
            });
        }
        const res = await fetch(url, apiReq);
        return await res.json()
    } catch (error) {
        console.log(error)
    }
}

// Delete Data

export const deleteData = async (url, id) => {
    try {
        const res = await fetch(`${url}/${id}`, {
            method: 'DELETE',
            headers: new Headers({
                'Accept': 'Application/json',
                'Authorization': getHeaders(),
            }),
        })
        return await res.json()

    }
    catch (error) {
        console.log(error)
    }
}

// update data 

export const putData = async (url, data, header = true) => {
    try {
        let apiReq = {
            method: 'PUT',
            body: data,
        }
        if (header) {
            apiReq.headers = new Headers({
                'Content-Type': 'application/json',
                'Authorization': getHeaders(),
            });

        }
        else {
            apiReq.headers = new Headers({
                'Authorization': getHeaders(),
            });
        }
        const res = await fetch(url, apiReq);
        return await res.json()
    }
    catch (error) {
        console.log(error)
    }
}


