import React from 'react'
import SignUpScreen from '../Components/Auth/SignUpScreen'
import ScrollToTop from '../Components/Main/ScrollToTop'

const SignUp = () => {
    return (
        <>
            <ScrollToTop />
            <SignUpScreen />
        </>
    )
}

export default SignUp