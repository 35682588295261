import React from 'react'
import BrandsScreen from '../Components/Brands/BrandsScreen'
import ScrollToTop from '../Components/Main/ScrollToTop'

const AddBrand = () => {
    return (
        <>
            <ScrollToTop />
            <BrandsScreen />
        </>
    )
}

export default AddBrand