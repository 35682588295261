import React from 'react';
import { FaLocationDot, FaPhoneVolume } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { Link } from 'react-router-dom';
import logo from "../../Assets/Images/logo.png";



const Footer = () => {
    return (
        <section className='hero-header  py-5 bg-dark'>
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-md-auto col-6">

                        <Link to={"/"}>
                            <img src={logo} width={200} alt="" />
                        </Link>
                    </div>

                    <div className="col-md-auto col-6 ">
                        <h5 className='fw-600 text-white'>
                            Quick Links
                        </h5>

                        <ul>
                            <li className='my-2'>
                                <Link to={"/products"} className='text-white'>
                                    Products
                                </Link>
                            </li>
                            <li className='my-2'>
                                <Link to={"/"} className='text-white'>
                                    Contact Us
                                </Link>
                            </li>
                            <li className='my-2'>
                                <Link to={"/"} className='text-white'>
                                    Privacy Policy
                                </Link>
                            </li>
                            <li className='my-2'>
                                <Link to={"/"} className='text-white'>
                                    Terms & Condition
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="col-md-auto col-6">
                        <h5 className='fw-600 text-white'>
                            Address
                        </h5>

                        <ul>
                            <li className='my-2'>

                                <a href="mailto: pharma.binabdullah@gmail.com" class="text-white text-decoration-none">
                                    <MdEmail size={20} className='me-2' />

                                    pharma.binabdullah@gmail.com</a>

                            </li>
                            <li className='my-2'>

                                <a href="tel: 923343333117" class="text-white text-decoration-none">
                                    <FaPhoneVolume className='me-2' />
                                    0334-3333117</a>
                            </li>
                            <li className='my-2'>
                                <a target="_blank"
                                    href="https://www.google.com/maps/place/Rawi+Hammal+hypoth%C3%A8que+%2F+mortgage/@45.564514,-73.655599,15z/data=!4m6!3m5!1s0x4cc9193683de582f:0x7b3e7e8620a3a773!8m2!3d45.5641539!4d-73.6557497!16s%2Fg%2F11fsn6nvh6?hl=en&amp;entry=ttu"
                                    class="text-white text-decoration-none">
                                    <FaLocationDot className='me-2 ' />

                                    National Medicine Market
                                    <br />
                                    V246+G9Q, Saddar Karachi, Karachi City, Sindh</a>
                            </li>

                        </ul>
                    </div>

                    <div className="col-md-3 col-6">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3620.2198843842366!2d67.0109835!3d24.8563386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33e0f8b13f7a1%3A0x52df3da6c8a24497!2sNational%20Medicine%20Market!5e0!3m2!1sen!2s!4v1707067859728!5m2!1sen!2s"
                            width="100%" height="300" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer