import React from 'react'
import { Card, Placeholder } from 'react-bootstrap'

const ProductPlaceholder = () => {
    return (
        <div className="container">
            <div className="row">

                <div className="col-md-3 col-6 mb-3">
                    <Card className='border-0 '>

                        <Card.Header className='bg-transparent border-0 pt-3'>

                            {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder style={{ width: "100%", height: "100px" }} xs={6} />
                            </Placeholder>
                        </Card.Header>
                        <Card.Body className=''>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder xs={6} /> {" "}
                                <Placeholder xs={5} />
                                <Placeholder xs={8} />
                            </Placeholder>
                        </Card.Body>

                        <Card.Footer className='bg-transparent border-0'>
                            <Placeholder className={"text-dark"} as={Card.Title} animation="glow">
                                <Placeholder xs={4} />
                            </Placeholder>
                        </Card.Footer>

                    </Card>
                </div>

                <div className="col-md-3 col-6 mb-3">
                    <Card className='border-0 '>

                        <Card.Header className='bg-transparent border-0 pt-3'>

                            {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder style={{ width: "100%", height: "100px" }} xs={6} />
                            </Placeholder>
                        </Card.Header>
                        <Card.Body className=''>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder xs={6} /> {" "}
                                <Placeholder xs={5} />
                                <Placeholder xs={8} />
                            </Placeholder>
                        </Card.Body>

                        <Card.Footer className='bg-transparent border-0'>
                            <Placeholder className={"text-dark"} as={Card.Title} animation="glow">
                                <Placeholder xs={4} />
                            </Placeholder>
                        </Card.Footer>

                    </Card>
                </div>

                <div className="col-md-3 col-6 mb-3">
                    <Card className='border-0 '>

                        <Card.Header className='bg-transparent border-0 pt-3'>

                            {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder style={{ width: "100%", height: "100px" }} xs={6} />
                            </Placeholder>
                        </Card.Header>
                        <Card.Body className=''>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder xs={6} /> {" "}
                                <Placeholder xs={5} />
                                <Placeholder xs={8} />
                            </Placeholder>
                        </Card.Body>

                        <Card.Footer className='bg-transparent border-0'>
                            <Placeholder className={"text-dark"} as={Card.Title} animation="glow">
                                <Placeholder  xs={4} />
                            </Placeholder>
                        </Card.Footer>

                    </Card>
                </div>

                <div className="col-md-3 col-6 mb-3">
                    <Card className='border-0 '>

                        <Card.Header className='bg-transparent border-0 pt-3'>

                            {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder style={{ width: "100%", height: "100px" }} xs={6} />
                            </Placeholder>
                        </Card.Header>
                        <Card.Body className=''>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder xs={6} /> {" "}
                                <Placeholder xs={5} />
                                <Placeholder xs={8} />
                            </Placeholder>
                        </Card.Body>

                        <Card.Footer className='bg-transparent border-0'>
                            <Placeholder className={"text-dark"} as={Card.Title} animation="glow">
                                <Placeholder  xs={4} />
                            </Placeholder>
                        </Card.Footer>

                    </Card>
                </div>
                

            </div>
        </div>
    )
}

export default ProductPlaceholder