import React from 'react'
import courier from "../../Assets/Images/courier.png";

import support from "../../Assets/Images/support.png";
import return2 from "../../Assets/Images/return.png";
import order from "../../Assets/Images/order.png"

const HomeServices = () => {
    return (
        <>
            <div className="container my-5">
                <div className="col-md-12 mb-4">
                    <div className="row ">
                        <div className="col-12 text-center">
                            <div className="row mb-3 justify-content-center align-items-center">
                                <div className="col-auto pe-0">

                                    <div className="border-heading border-warning" >

                                    </div>
                                </div>
                                <div className="col-auto">
                                    <h5 className='mb-0 fw-600 text-warning text-uppercase'>
                                        Services
                                    </h5>
                                </div>

                                <div className="col-auto ps-0">

                                    <div className="border-heading border-warning" >

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <h2 className='fw-600 text-center text-uppercase text-muted'>
                                 Quality Services, At your door step
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-3 col-6 mb-3">
                            <div className="card  hoverShadow border-0 p-3 h-100 shadow">
                                <div className="row justify-content-center">
                                    <div className="col-md-10">
                                        <div style={{ width: "100px", height: "100px " }}
                                            className="m-auto p-2 border-dash border-2 d-flex align-items-center justify-content-center rounded-circle border-dark">
                                            <img src={courier} width="70%" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <h5 className='text-center fw-600 mb-0 '>
                                            Fast Delivery

                                        </h5>
                                        <p className='text-muted text-center s-14 text-capitalize fw-500'>
                                            Shipping in 1-4 days
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-md-12 p-5">
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="col-md-3 col-6 mb-3">
                            <div className="card border-0 p-3  hoverShadow h-100 shadow">
                                <div className="row justify-content-center">
                                    <div className="col-md-10">
                                        <div style={{ width: "100px", height: "100px " }}
                                            className="m-auto border-dash border-2 p-2 d-flex align-items-center justify-content-center rounded-circle border-dark">
                                            <img src={return2} width="70%" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <h5 className='text-center fw-600 mb-0 '>
                                            Free Return

                                        </h5>
                                        <p className='text-muted text-center s-14 text-capitalize fw-500'>
                                            Within 7 Days
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-md-12 p-5">
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="col-md-3 col-6 mb-3">
                            <div className="card border-0 p-3 h-100 shadow  hoverShadow">
                                <div className="row justify-content-center">
                                    <div className="col-md-10">
                                        <div style={{ width: "100px", height: "100px " }}
                                            className="m-auto p-2 border-dash border-2 d-flex align-items-center justify-content-center rounded-circle border-dark">
                                            <img src={support} width="70%" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <h5 className='text-center fw-600 mb-0 '>
                                            Customer Support

                                        </h5>
                                        <p className='text-muted text-center s-14 text-capitalize fw-500'>
                                            Phone And Email
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-md-12 p-5">
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="col-md-3 col-6 mb-3">
                            <div className="card border-0 p-3 h-100 shadow  hoverShadow">
                                <div className="row justify-content-center">
                                    <div className="col-md-10">
                                        <div style={{ width: "100px", height: "100px " }}
                                            className="m-auto p-2 border-dash border-2 d-flex align-items-center justify-content-center rounded-circle border-dark">
                                            <img src={order} width="70%" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <h5 className='text-center fw-600 mb-0 '>
                                            Online Order

                                        </h5>
                                        <p className='text-muted text-center s-14 text-capitalize fw-500'>
                                            healthcare products online.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-md-12 p-5">
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default HomeServices