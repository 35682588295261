import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getBrand } from '../../Redux/Slices/brandSlice';
import HomePlaceholder from '../Placeholders/HomePlaceholder';

const HomeBrand = () => {

    const dispatch = useDispatch()
    const { brandList, loading } = useSelector((state) => state.brand)

    useEffect(() => {
        dispatch((getBrand()))
    }, [])



    // const data = [
    //     {
    //         id: 1,
    //         name: "Hamdard",
    //         img: hamdard
    //     },
    //     {
    //         id: 2,
    //         name: "hemani",
    //         img: hemani
    //     },
    //     {
    //         id: 1,
    //         name: "saeed ghani",
    //         img: saeedGhani
    //     },
    //     {
    //         id: 1,
    //         name: "qarshi",
    //         img: qarshi
    //     },
    // ]
    return (
        <>
            <div className="container my-5">
                <div className="row justify-content-center py-5">
                    <div className="col-md-12 mb-4">
                        <div className="row ">
                            <div className="col-12 text-center">
                                <div className="row mb-3 justify-content-center align-items-center">
                                    <div className="col-auto pe-0">

                                        <div className="border-heading border-warning" >

                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <h5 className='mb-0 fw-600 text-warning text-uppercase'>
                                            Brands
                                        </h5>
                                    </div>

                                    <div className="col-auto ps-0">

                                        <div className="border-heading border-warning" >

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <h2 className='fw-600 text-center text-uppercase text-muted'>
                                    providing Quality Brands, Your Trusted Choice
                                </h2>
                            </div>
                        </div>
                    </div>


                    {loading ?
                        <>
                            <HomePlaceholder />
                        </>
                        :
                        <>
                            {Array.isArray(brandList) && (
                                brandList?.map((item, i) => {
                                    return (
                                        <>
                                            <div className="col-md-3 col-6 mt-3" key={i}>
                                                <Link to={`/products?category_id=&brand_id=${item?.brandName}`} className='text-decoration-none'>
                                                    <div className="card align-items-center  hoverShadow text-center border-0 shadow px-3 py-5 h-100 justify-content-center" >
                                                        <div className="row justify-content-center align-items-center">
                                                            <div className="col-10">
                                                                <img src={item?.image} width="100%" alt="" />

                                                            </div>
                                                            <div className="col-12">
                                                                <h5 className='mb-0 fw-600 py-3 text-capitalize'>
                                                                    {item?.brandName}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </>
                                    )
                                })
                            )}
                        </>
                    }




                </div>
            </div>

        </>
    )
}

export default HomeBrand