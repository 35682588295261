import React, { useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { getBrand } from '../../Redux/Slices/brandSlice';
import { getCategory } from '../../Redux/Slices/categorySlice';
import { getFilterProduct } from '../../Redux/Slices/productSlice';
import BreadcrumScreen from '../Breadcrum/BreadcrumScreen';
import ProductPlaceholder from '../Placeholders/ProductPlaceholder';


const ProductsScreen = () => {


    const dispatch = useDispatch()
    const { products, loading } = useSelector((state) => state.product)
    const { categoryList } = useSelector((state) => state.category)
    const { brandList } = useSelector((state) => state.brand)

    const [brand, setBrand] = useState("")
    const [category, setCategory] = useState("")

    const [filteredProducts, setFilteredProducts] = useState(products);

    const searchProduct = (event) => {
        const value = event.target.value.toLowerCase();
        console.log("value", value)
        const filtered = products.filter(product => product.title.toLowerCase().includes(value));
        setFilteredProducts(filtered);
    };


    const [searchParams, setSearchParams] = useSearchParams();

    const brandId = searchParams.get('brand_id');
    const categoryId = searchParams.get('category_id');

    useEffect(() => {
        if (brandId) {
            setBrand(brandId)
        }
        if (categoryId) {
            setCategory(categoryId)
        }
        handleLoadData(brandId, categoryId)
    }, [brandId, categoryId]);



    useEffect(() => {
        // dispatch(getProducts())
        dispatch(getCategory())
        dispatch(getBrand())
    }, [])

    const handleFilter = () => {
        let query = '';
        if (brand && category) {
            query += `?brand_id=${brand}&category_id=${category}`;
        }
        if (brand && !category) {
            query += `?brand_id=${brand}&category_id=`;
        }
        if (!brand && category) {
            query += `?category_id=${category}&brand_id=`;
        }
        const queryParams = {
            brand_id: brand,
            category_id: category
        }
        setSearchParams(queryParams);
        dispatch(getFilterProduct(query))
    }

    const handleLoadData = (paramBrand, paramCategory) => {
        let query = '';
        if (paramBrand && paramCategory) {
            query += `?brand_id=${paramBrand}&category_id=${paramCategory}`;
        }
        if (paramBrand && !paramCategory) {
            query += `?brand_id=${paramBrand}&category_id=`;
        }
        if (!paramBrand && paramCategory) {
            query += `?category_id=${paramCategory}&brand_id=`;
        }
        const queryParams = {
            brand_id: paramBrand,
            category_id: paramCategory
        }
        dispatch(getFilterProduct(query))
    }





    return (
        <>

            <BreadcrumScreen props=
                {{ page: 'Product', title: 'Our Products' }}
            />

            <div className="container-fluid my-5">
                <div className="row">
                    <div className="col-md-3">
                        <div className="card p-4 border-0 shadow-sm">

                            <Form>

                                <Form.Group className="mb-3">
                                    <Form.Label className='fw-600 s-14 text-capitalize'>sort by category</Form.Label>
                                    <Form.Select onChange={(e) => setCategory(e.target.value)} >

                                        <option value={""}>All Category</option>
                                        {Array.isArray(categoryList) && categoryList?.length ? (
                                            categoryList?.map((item, i) => {
                                                return (
                                                    <option selected={category === item?.category} value={item?.category}>{item?.category}</option>
                                                )
                                            })
                                        ) : (

                                            <option>No Category Available</option>
                                        )}

                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label className='fw-600 s-14 text-capitalize'>sort by brand</Form.Label>
                                    <Form.Select onChange={(e) => setBrand(e.target.value)}>

                                        <option value={""}>All Brands</option>
                                        {Array.isArray(brandList) && brandList?.length ? (
                                            brandList?.map((item, i) => {
                                                return (
                                                    <option selected={brand === item?.brandName} value={item?.brandName}>{item?.brandName}</option>
                                                )
                                            })
                                        ) : (
                                            <option>No Brand Available</option>
                                        )}
                                    </Form.Select>
                                </Form.Group>

                                <Button onClick={handleFilter} type="button" className='btn btn-dark text-white w-100'>
                                    Submit
                                </Button>

                            </Form>

                        </div>
                    </div>

                    <div className="col-md-9 mt-5 mt-md-0">

                        <div className="row mb-3 justify-content-between align-items-center">

                            <div className="col-auto">
                                <div className="row align-items-center">


                                    <div className="col-auto pe-0">

                                        <div className="border-heading border-warning" >

                                        </div>
                                    </div>
                                    <div className="col-auto ">
                                        <h5 className='mb-0 fw-600 text-warning text-uppercase'>
                                            All Products
                                        </h5>
                                    </div>

                                    <div className="col-auto ps-0">

                                        <div className="border-heading border-warning" >

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-auto ">
                                <input type="text" placeholder='Search Product' onChange={searchProduct} className='form-control' />
                            </div>
                        </div>

                        <div className="row">

                            {
                                loading ?
                                    <>
                                        <ProductPlaceholder />

                                    </>
                                    :
                                    <>
                                        {Array.isArray(filteredProducts) && filteredProducts.length > 0 ?
                                            (filteredProducts?.map((item, i) => {
                                                return (
                                                    <div className="col-md-3 col-6 my-3" key={i}>

                                                        <Link to={`/product-detail/${item?.slug}`} className='text-decoration-none '>
                                                            <Card className=' border-0 h-100  shadow-sm'>
                                                                <Card.Header className='p-md-3 border-0 bg-transparent'>

                                                                    <Card.Img variant="top" width="100%" height={100} src={item?.image} style={{ objectFit: "contain" }} />
                                                                </Card.Header>
                                                                <Card.Body>
                                                                    {/* <Card.Title>Card Title</Card.Title> */}
                                                                    <Card.Text className='fw-500 s-14' >
                                                                        {item?.title}
                                                                    </Card.Text>
                                                                </Card.Body>

                                                                <Card.Footer className='bg-transparent border-0'>
                                                                    <p className='mb-0 fw-600 text-warning'>

                                                                        Rs.{item?.price}
                                                                    </p>
                                                                </Card.Footer>
                                                            </Card>
                                                        </Link>

                                                    </div>
                                                )
                                            })) :
                                            (
                                                <>
                                                    <h5>
                                                        No Product Available
                                                    </h5>
                                                </>
                                            )
                                        }
                                    </>
                            }


                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductsScreen