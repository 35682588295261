import React from 'react'
import HomeBanner from '../Components/Home/HomeBanner'
import HomeCategory from '../Components/Home/HomeCategory'
import HomeBrand from '../Components/Home/HomeBrand'
import HomeServices from '../Components/Home/HomeServices'
import ScrollToTop from '../Components/Main/ScrollToTop'

const Home = () => {
    return (
        <>
        <ScrollToTop />
            <HomeBanner />
            <HomeCategory />
            <HomeBrand />
            <HomeServices />
        </>
    )
}

export default Home