import React from 'react'
import CreateProductsScreen from '../Components/Products/CreateProductsScreen'
import ScrollToTop from '../Components/Main/ScrollToTop'

const CreateProduct = () => {
    return (
        <>
        <ScrollToTop />
            <CreateProductsScreen />
        </>
    )
}

export default CreateProduct