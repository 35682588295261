import React, { useEffect, useState } from 'react'
import { brand } from '../../Services/variable';
import { sweetAlert } from '../../lib/sweetalert';
import { deleteData, postData, putData } from '../../Services/Api';
import { useDispatch, useSelector } from 'react-redux';
import { getBrand } from '../../Redux/Slices/brandSlice';


const UseBrand = () => {

    const [branId, setBranId] = useState()
    const [image, setImage] = useState()
    const [name, setName] = useState()
    const [isLoading, setIsloading] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const dispatch = useDispatch()
    const { loading, brandList } = useSelector((state) => state.brand)

    useEffect(() => {
        dispatch(getBrand())
    }, [])

    const handleAddEditCategory = () => {
        setIsloading(true)
        if (branId) {

            const form = new FormData()


            form.append('id', branId)
            form.append('image', image)
            form.append('brandName', name)

            putData(brand, form, false)
                .then(async (res) => {
                    setShow(false)
                    setIsloading(false)
                    await sweetAlert(res?.message, res?.status)
                    dispatch(getBrand())
                })
                .catch(async (err) => {
                    console.log(err)
                })
        }
        else {

            const form = new FormData()

            form.append('image', image)
            form.append('brandName', name)

            postData(brand, form, false)
                .then(async (res) => {
                    setName("")
                    setIsloading(false)
                    setShow(false)
                    dispatch(getBrand())
                    await sweetAlert(res?.message, res?.status)
                })
                .catch(async (err) => {
                    await sweetAlert(err?.message, err?.status)

                })
        }

    }

    const handleFileChange = (event) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };


    const handleDelete = (id) => {
        setIsloading(true)

        deleteData(brand, id)
            .then(async (res) => {
                setIsloading(false)
                await sweetAlert(res?.message, res?.status)
                dispatch(getBrand())
            })
            .catch(async (err) => {
                setIsloading(false)
                await sweetAlert(err?.message, err?.status)

            })
    }


    const handleShow = () => {
        setBranId(null)
        setName("")
        setShow(true)
    }

    const handleEditModal = (item) => {
        setBranId(item?._id)
        setName(item?.brandName)
        setImage(item?.image)
        setShow(true)

    }


    return (
        [
            brandList,
            loading,
            branId,
            setBranId,
            image,
            setImage,
            name,
            setName,
            show,
            setShow,
            handleClose,
            handleAddEditCategory,
            handleDelete,
            handleShow,
            handleEditModal,
            handleFileChange,
            isLoading
        ]
    )
}

export default UseBrand