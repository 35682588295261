import React from 'react'
import { Table } from 'react-bootstrap'
import { FaPen, FaPlus } from 'react-icons/fa'
import { FaTrashCan } from 'react-icons/fa6'
import { Link } from 'react-router-dom'
import UseProduct from '../../Hooks/product/UseProduct'
import { imageUrl } from '../../Services/variable'

const AddProductScreen = () => {

    const [
        products,
        slug,
        categoryList,
        subCategoryList,
        brandList,
        id,
        setId,
        image,
        setImage,
        name,
        setName,
        price,
        setPrice,
        brand,
        setBrand,
        brandId,
        setBrandId,
        category,
        setCategory,
        categoryId,
        setCategoryId,
        subCat,
        setSubCat,
        subCatId,
        setSubCatId,
        description,
        setDescription,
        handleAddProducts,
        getProduct,
        handleDelete,
        isLoading,
        delLoading
    ] = UseProduct()


    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h2 className='mb-0 '>
                            Products
                        </h2>
                    </div>

                    <div className="col-auto">
                        <Link
                            to={"/add-edit-product"}
                            // onClick={handleShow}
                            className='btn btn-add '>
                            <FaPlus />
                        </Link>
                    </div>


                    <div className="col-md-12 mt-5">

                        <Table bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Image</th>
                                    <th>Product Name</th>
                                    <th>Description</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {Array.isArray(products) && products?.length > 0 ? (
                                    products.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td><img src={item?.image} width={60} alt="" /></td>
                                            <td>{item?.title}</td>
                                            <td>{item?.description}</td>
                                            <td>
                                                <div className='d-flex'>
                                                    <Link className='btn btn-edit me-1'
                                                        to={`/add-edit-product/${item?.slug}`}
                                                    // onClick={() => handleShowEditModal(item)}
                                                    >
                                                        <FaPen />
                                                    </Link>
                                                    <button disabled={delLoading} className='btn btn-delete'
                                                        onClick={() => handleDelete(item?._id)}
                                                    >
                                                        <FaTrashCan />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5 " className='fw-600 text-center'>No products available</td>
                                    </tr>
                                )}

                            </tbody>
                        </Table>
                    </div>

                </div>
            </div>
        </>
    )
}

export default AddProductScreen