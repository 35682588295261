import React from 'react'
import MyOrderScreen from '../Components/Order/MyOrderScreen'
import BreadcrumScreen from '../Components/Breadcrum/BreadcrumScreen'
import ScrollToTop from '../Components/Main/ScrollToTop'

const MyOrder = () => {
    return (
        <>
        <ScrollToTop />
            <BreadcrumScreen props=
                {{ page: 'My Orders', title: 'My Orders' }}
            />
            <MyOrderScreen />
        </>
    )
}

export default MyOrder