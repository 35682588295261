import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCategory } from '../../Redux/Slices/categorySlice';
import HomePlaceholder from '../Placeholders/HomePlaceholder';

const HomeCategory = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch((getCategory()))
    }, [])

    const { categoryList, loading } = useSelector((state) => state.category)

    return (
        <>
            <div className="container my-5">
                <div className="row justify-content-center py-5">
                    <div className="col-md-12 mb-4">
                        <div className="row ">
                            <div className="col-12 text-center">
                                <div className="row mb-3 justify-content-center align-items-center">
                                    <div className="col-auto pe-0">

                                        <div className="border-heading border-warning" >

                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <h5 className='mb-0 fw-600 text-warning text-uppercase'>
                                            categories
                                        </h5>
                                    </div>

                                    <div className="col-auto ps-0">

                                        <div className="border-heading border-warning" >

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <h2 className='fw-600 text-center text-uppercase text-muted'>
                                    Nature's Cure – Your Holistic Wellness Partners
                                </h2>
                            </div>
                        </div>
                    </div>


                    {loading ?
                        <>
                            <HomePlaceholder />
                        </> :
                        <>
                            {Array.isArray(categoryList) && (
                                categoryList?.map((item, i) => {
                                    return (
                                        <>
                                            {/* <div className="col-md-auto col-3" key={i}>
                                            <Link to={"/products"} className='text-decoration-none'>
                                                <div className="card  rounded-circle text-center border-0 shadow d-flex justify-content-center" style={{ height: "220px", width: "220px" }}>
                                                    <div className="row justify-content-center">
                                                        <div className="col-6">
                                                            <img src={item?.img} width="100%" alt="" />

                                                        </div>
                                                        <div className="col-12">
                                                            <h5 className='mb-0 fw-600 py-3 text-capitalize'>
                                                                {item?.title}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div> */}
                                            <div className="col-md-3 col-6 mt-3" key={i}>
                                                <Link to={`/products?brand_id=&category_id=${item?.category}`} className='text-decoration-none'>
                                                    <div className="card px-3 py-5 h-100 hoverShadow text-center border-0 shadow d-flex justify-content-center">
                                                        <div className="row justify-content-center">
                                                            <div className=" col-md-6 col-10">
                                                                <img src={item?.image} width="100%" alt="" />

                                                            </div>
                                                            <div className="col-12">
                                                                <h5 className='mb-0 fw-600 py-3 text-capitalize'>
                                                                    {item?.category}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </>
                                    )
                                })
                            )}
                        </>
                    }





                </div>
            </div>
        </>
    )
}

export default HomeCategory