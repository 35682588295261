import React from 'react'
import { Table } from 'react-bootstrap'
import hemani from "../../Assets/Images/hemani.png";
import { BsFillTrash3Fill } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { FaMinus, FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { removeFromCart, increment, decrement } from '../../Redux/Slices/cartSlice';
import { imageUrl } from '../../Services/variable';


const CartScreen = () => {

  const cartItems = useSelector(state => state.cart)
  const dispatch = useDispatch();

  const handleRemove = (val) => {
    dispatch(removeFromCart(val))
  }

  const calculatePrice = () => {
    let price = 0;
    if (cartItems?.cart?.length > 0) {
      cartItems?.cart?.map((i) => {
        price += i.price * i.quantity

      });
    }
    return price;
  }


  return (
    <div className="container my-5">
      <div className="row justify-content-end">
        <div className="col-md-12">
          <Table responsive="sm" className='shadow rounded-2 overflow-hidden'>
            <thead  >
              <tr className='bg-dark'>
                <th className='bg-dark text-white hero-header'>#</th>
                <th className='bg-dark text-white hero-header'>Product</th>
                <th className='bg-dark text-white hero-header'>Quantity</th>
                <th className='bg-dark text-white hero-header'> Sub Total</th>
                <th className='bg-dark text-white hero-header'></th>

              </tr>
            </thead>
            <tbody style={{ verticalAlign: 'middle' }}>
              {cartItems?.cart?.length > 0 ? (
                cartItems?.cart?.map((val, index) => (
                  <>
                    <tr>

                      <td>{index + 1}</td>
                      <td>
                        <div className="row align-items-center">
                          <div className="col-auto p-0">
                            <img src={`${imageUrl}${val?.image}`} className='me-3' width={50} style={{ objectFit: 'contain' }} alt="" />
                          </div>
                          <div className="col p-0">
                            <p className='mb-0'> <span className='fw-600 text-dark s-14 '>Name : </span> <span className='fw-600 s-14'>{val.title}</span></p>
                            <p> <span className='fw-600 text-dark s-14'>Price : </span> <span className='fw-600 s-14'>{val.price}</span></p>
                          </div>
                        </div>
                      </td>
                      <td>

                        <div className="row align-items-center">

                          <div className="col-auto">
                            <button onClick={() => dispatch(decrement(val))} type=""
                              className='btn btn-danger p-0 
                                 d-flex align-items-center justify-content-center
                                 border-0 rounded-circle'
                              style={{ height: "34px", width: "34px" }}
                            >
                              <FaMinus size={15} className='text-white' />

                            </button>

                          </div>

                          <div className="col-3 text-center">

                            <span className='s-18 '>{val.quantity}</span>
                          </div>

                          <div className="col-auto">
                            <button onClick={() => dispatch(increment(val))} type=""
                              className='btn btn-dark p-0 
                                d-flex align-items-center justify-content-center
                                border-0 rounded-circle'
                              style={{ height: "34px", width: "34px" }}>
                              <FaPlus size={15} className='text-white' />
                            </button>
                          </div>
                        </div>




                      </td>
                      <td>Rs {val.price * val.quantity}</td>
                      <td><BsFillTrash3Fill
                        onClick={() => handleRemove(val)}
                        size={24} className="text-danger" /></td>
                    </tr >
                  </>
                ))
              ) : (
                <>
                  <tr >
                    <td className='text-center fw-600' colSpan={5}>
                      Your Cart Is Empty

                    </td>

                  </tr>
                </>
              )
              }



            </tbody>
          </Table>

        </div>

        <div className="col-md-4 ">
          <div className="card shadow border-0  p-3">
            <div className="row mb-3">
              <div className="col">
                <div className="fw-600 s-14">
                  Sub Total
                </div>
              </div>
              <div className="col-auto">
                <div className="fw-600 s-14">
                  {calculatePrice()}
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <div className="fw-600 s-14">
                  Total Amount
                </div>
              </div>
              <div className="col-auto">
                <div className="fw-600 s-14">
                  {calculatePrice()}

                </div>
              </div>
            </div>

            <div>
              <Link to={"/checkout"} className='btn btn-dark text-white fw-600'>
                Proceed To Checkout
              </Link>
            </div>

          </div>
        </div>
      </div >
    </div >
  )
}

export default CartScreen