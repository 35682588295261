import React, { useEffect, useState } from 'react'
import { FaPen, FaPlus, FaTrashCan } from "react-icons/fa6";
// import { Button, Modal } from 'bootstrap';
import { Button, Form, Modal, Spinner, Table } from 'react-bootstrap';
import { deleteData, postData, putData } from '../../Services/Api';
import { addCategory, imageUrl } from '../../Services/variable';
import { sweetAlert } from '../../lib/sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { getCategory } from '../../Redux/Slices/categorySlice';
import useCategory from '../../Hooks/category/UseCategory';




const AddCategoryScreen = () => {

  // const [catId, setCatId] = useState()
  // const [category, setCategory] = useState("")
  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);

  // const dispatch = useDispatch()
  // const { loading, categoryList } = useSelector((state) => state.category)

  // const handleAddEditCategory = () => {
  //   if (catId) {
  //     const data = {
  //       id: catId,
  //       category: category
  //     }
  //     putData(addCategory, JSON.stringify(data))
  //       .then(async (res) => {
  //         setShow(false)
  //         await sweetAlert(res?.message, res?.status)
  //         dispatch(getCategory())
  //       })
  //       .catch(async (err) => {
  //         console.log(err)
  //       })
  //   }
  //   else {
  //     const data = {
  //       category: category
  //     }
  //     postData(addCategory, JSON.stringify(data))
  //       .then(async (res) => {
  //         setShow(false)
  //         setCategory("")
  //         dispatch(getCategory())
  //         await sweetAlert(res?.message, res?.status)
  //       })
  //       .catch(async (err) => {
  //         await sweetAlert(err?.message, err?.status)

  //       })
  //   }

  // }

  // const handleDelete = (id) => {
  //   deleteData(addCategory, id)
  //     .then(async (res) => {
  //       await sweetAlert(res?.message, res?.status)
  //       dispatch(getCategory())
  //     })
  //     .catch(async (err) => {
  //       await sweetAlert(err?.message, err?.status)

  //     })
  // }

  // useEffect(() => {
  //   dispatch(getCategory())
  // }, [])

  // const handleShow = () => {
  //   setCatId(null)
  //   setCategory("")
  //   setShow(true)
  // }

  // const handleEditModal = (item) => {
  //   setCatId(item?._id)
  //   setCategory(item?.category)
  //   setShow(true)
  // }

  const [
    loading,
    categoryList,
    image,
    setImage,
    catId,
    setCatId,
    category,
    setCategory,
    show,
    setShow,
    handleClose,
    handleAddEditCategory,
    handleDelete,
    handleShow,
    handleEditModal,
    handleFileChange,
    isloading,
    setIsLoading
  ] = useCategory()

  if (loading) return <> <h4 className='fw-600 text-center'>Loading...</h4></>

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col">
            <h2 className='mb-0 '>
              Categories
            </h2>
          </div>

          <div className="col-auto">
            <button onClick={handleShow} className='btn btn-add '>
              <FaPlus />
            </button>
          </div>

          <div className="col-md-12 mt-4">
            <Table bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Image</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  (Array.isArray(categoryList) && categoryList.length > 0) ? (
                    categoryList.map((item, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td><img width={60} src={item?.image} alt="" /></td>
                        <td>{item?.category}</td>
                        <td>
                          <div className='d-flex'>
                            <button className='btn btn-edit me-1' onClick={() => handleEditModal(item)}>
                              <FaPen />
                            </button>
                            <button disabled={isloading} className='btn btn-delete' onClick={() => handleDelete(item._id)}>
                              <FaTrashCan />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className='text-capitalize text-center fw-600'>No category available </td>
                    </tr>
                  )
                }


              </tbody>
            </Table>
          </div>
        </div>


        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{catId ? "Edit " : "Add "} Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form>


              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label className='modal-label'>Category Image</Form.Label>
                <Form.Control onChange={handleFileChange} type="file" placeholder="Category Name" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label className='modal-label'>Category Name</Form.Label>
                <Form.Control value={category} onChange={e => setCategory(e.target.value)} type="text" placeholder="Category Name" />
              </Form.Group>

            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" size='sm' onClick={handleClose}>
              Close
            </Button>
            <Button variant="dark" className='text-white' size='sm' onClick={handleAddEditCategory}>
              {
                isloading ? <><Spinner size='sm' animation="border" /></> : "Save"
              }

            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>

  )
}

export default AddCategoryScreen