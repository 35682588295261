import React from 'react'
import AddProductScreen from '../Components/Products/AddProductScreen'
import ScrollToTop from '../Components/Main/ScrollToTop'

const AddProduct = () => {
    return (
        <>
        <ScrollToTop />
            <AddProductScreen />
        </>
    )
}

export default AddProduct