import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { product } from '../../Services/variable'


const token = localStorage.getItem("access_token");
const initialState = {
    products: [],
    loading: false,
}

export const getProducts = createAsyncThunk(
    "product/getProducts",
    async () => {
        const res = await fetch(product, {
            // mode: 'no-cors',
            method: 'GET',
            headers: new Headers({
                'Accept': 'application/json',
                // 'Content-Type': 'application/json'
            })
        }
        )
        const resJson = await res.json()
        return resJson
    }
)

export const getFilterProduct = createAsyncThunk(
    "product/getFilterProduct",
    async (query) => {
        const res = await fetch(`${product}${query}`, {
            // mode: 'no-cors',
            method: 'GET',
            headers: new Headers({
                'Accept': 'application/json',
                // 'Content-Type': 'application/json'
            })
        }
        )
        const resJson = await res.json()
        return resJson
    }
)

export const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {},
    extraReducers: {
        [getProducts.pending]: (state) => {
            state.loading = true
        },
        [getProducts.fulfilled]: (state, { payload }) => {
            state.products = payload?.data;
            state.loading = false

            // console.log("state.data ======  " , data) 
        },
        [getProducts.rejected]: (state) => {
            state.loading = false
            state.products = []
        },

        [getFilterProduct.pending]: (state) => {
            state.loading = true;
            state.products = []
        },
        [getFilterProduct.fulfilled]: (state, { payload }) => {

            state.loading = false;
            state.products = payload?.data
        },
        [getFilterProduct.rejected]: (state) => {
            state.loading = false
            state.products = []
        }
    }
})

export const productReducer = productSlice.reducer


