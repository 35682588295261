import React from 'react'
import ProductsScreen from '../Components/Products/ProductsScreen'
import ScrollToTop from '../Components/Main/ScrollToTop'

const Product = () => {
    return (
        <>
        <ScrollToTop />
            <ProductsScreen />
        </>
    )
}

export default Product