import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { productReducer } from "../Redux/Slices/productSlice";
import cartReducer from "../Redux/Slices/cartSlice";
import { categoryReducer } from "./Slices/categorySlice"
import { brandReducer } from "./Slices/brandSlice"

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk';
import { subCategoryReducer } from './Slices/subCategorySlice';
import { authReducer } from './Slices/authSlice';
import { orderReducer } from './Slices/orderSlice';

const persistConfig = {
    key: 'root',
    storage,
}

const rootReducer = combineReducers({
    auth: authReducer,
    cart: cartReducer,
    product: productReducer,
    category: categoryReducer,
    brand: brandReducer,
    subCategory: subCategoryReducer,
    order : orderReducer

});

const persistedReducer = persistReducer(persistConfig, rootReducer)



export const store = configureStore({
    // reducer: {
    //     cart: cartReducer,
    // },
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
})


export const persistor = persistStore(store)
