import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addSubCategory } from "../../Services/variable";

const initialState = {
    subCategoryList: [],
    loading: false
}

export const getSubCategory = createAsyncThunk(
    "subCategory/getSubCategory",
    async () => {
        const res = await fetch(addSubCategory, {
            // mode: 'no-cors',
            method: 'GET',
            headers: new Headers({
                'Accept': 'application/json',
                // 'Content-Type': 'application/json'
            })
        }
        )
        const resJson = await res.json()
        return resJson
    }
)

export const subCategorySlice = createSlice({
    name: 'subCategory',
    initialState,
    reducers: {},
    extraReducers: {
        [getSubCategory.pending]: (state) => {
            state.loading = true
            // state.subCategoryList = []
        },
        [getSubCategory.fulfilled]: (state, { payload }) => {
            state.subCategoryList = payload?.data
            state.loading = false

        },
        [getSubCategory.rejected]: (state) => {
            state.loading = false
            state.subCategoryList = []
        }
    }
})

export const subCategoryReducer = subCategorySlice.reducer