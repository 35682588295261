import React, { useState } from 'react'
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { toastAlert } from '../../lib/toastAlert';
import { userLogin } from '../../Redux/Slices/authSlice';

const LoginScreen = () => {

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const dispatch = useDispatch()
    const navigate = useNavigate()


    const handleLogin = async (e) => {
        e.preventDefault();

        const body = {
            email,
            password
        }

        try {
            const response = await dispatch(userLogin(body)).unwrap();
            if (response?.status === "success") {
                setEmail("")
                setPassword("")
                toastAlert(response?.message, response?.status)
                navigate("/")
            }
            else {
                toastAlert(response?.message, response?.status)
            }
        } catch (error) {
            console.log("errr in login", error)
        }
    }

    return (
        <>
            <section className='hero-header bg-dark' style={{ height: "100vh" }}>

                <div className="container">
                    <div className="row align-items-center justify-content-center" style={{ height: "100vh" }} >
                        <div className="col-md-5">
                            <div className="card overflow-hidden border-0 shadow">
                                <div className="triangle-topleft">
                                </div>


                                <h3 className='fw-600 text-center pt-3'>
                                    Login
                                </h3>
                                <Form className='p-3' onSubmit={handleLogin}>

                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Email address"
                                        className="mb-3"
                                    >
                                        <Form.Control value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="" />
                                    </FloatingLabel>

                                    <FloatingLabel controlId="floatingPassword" label="Password">
                                        <Form.Control value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="" />
                                    </FloatingLabel>

                                    <p className='mt-3 text-end fw-500 textcapitalize text-dark mb-0'>forgot password?</p>


                                    <Button type='submit' className='mt-3 btn w-100 btn-dark fw-600 text-white'>
                                        <span className='fw-600'>
                                            Login
                                        </span>
                                    </Button>

                                    <p className='mt-3 text-center fw-500'>Don't You Have An Account?
                                        <span>
                                            <Link className='ms-2 text-decoration-none text-dark' to={"/signup"}>
                                                Sign Up
                                            </Link>
                                        </span>
                                    </p>

                                </Form>


                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default LoginScreen