import logo from './logo.svg';
import './App.css';
import AppRouting from './Routing/AppRouting';

function App() {
  return (
    <>
      <AppRouting />
    </>
  );
}

export default App;
