import React, { useState } from 'react'
import { putData } from '../../Services/Api';
import { resetPass } from '../../Services/variable';
import { sweetAlert } from '../../lib/sweetalert';
import { Button, Form, Spinner } from 'react-bootstrap';

const ResetPasswordScreen = () => {
    const [password, setPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [reTypePassword, setReTypePassword] = useState();
    const [checkPass, setCheckPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleChangePass = (e) => {
        e.preventDefault();
        setLoading(true)
        if (newPassword === reTypePassword) {
            const data = {
                password: password,
                newPassword: newPassword
            }
            putData(resetPass, JSON.stringify(data))
                .then(async (result) => {
                    setLoading(false)
                    setPassword("")
                    setNewPassword("")
                    setReTypePassword("")
                    await sweetAlert(result?.message, result?.status)

                })
                .catch(async (err) => {
                    await sweetAlert(err?.message, err?.status)
                    setPassword("")
                    setNewPassword("")
                    setReTypePassword("")
                    setLoading(false)

                })
        } else {
            setCheckPassword(true)
        }

    }
    return (
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <h2>Change Password</h2>
                    </div>

                    <div className="col-md-6 mt-3">
                        <div className="card border-0 shadow p-3">
                            <Form onSubmit={handleChangePass}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Old Password</Form.Label>
                                    <Form.Control value={password} onChange={e => { setPassword(e.target.value) }} type="password" placeholder="Enter Your Old Password" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control value={newPassword} onChange={e => { setNewPassword(e.target.value) }} type="password" placeholder="Enter Your New Password" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Re-Type New Password</Form.Label>
                                    <Form.Control value={reTypePassword} onChange={e => { setReTypePassword(e.target.value) }} type="password" placeholder="Re-Type Your New Password" />
                                    {
                                        checkPass ? (
                                            <Form.Text className="text-danger  text-center">
                                                New Password and Re-Type Password does not match
                                            </Form.Text>
                                        ) : ""
                                    }

                                </Form.Group>



                                <Button variant="dark" className='text-white w-100' type="submit">
                                    {
                                        loading ?
                                            <>
                                                <Spinner animation="border" size="sm" />
                                            </> : "Submit"
                                    }

                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPasswordScreen