import React, { useEffect } from 'react'
import { getOrder } from '../../Redux/Slices/orderSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BsEye } from 'react-icons/bs';

const AllOrdersScreen = () => {

    const dispatch = useDispatch();
    const { orderList } = useSelector((state) => state.order)

    useEffect(() => {
        dispatch(getOrder())
    }, [])


    return (
        <>
            <div className="row">
                <div className="col">

                    <h5 className='fw-600 mb-4'>
                        Orders
                    </h5>
                </div>


                <div className="col-md-12 mb-5">
                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th>Order No</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Address</th>
                                <th>Status</th>
                                <th>Total</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Array.isArray(orderList) && (
                                    orderList && orderList.map((items, index) => (
                                        <>
                                            <tr >

                                                <td className={` ${items?.is_read ? "" : "bg-light"}`}>{items?.orderNo}</td>
                                                <td className={` ${items?.is_read ? "" : "bg-light"}`}>{items?.name}</td>
                                                <td className={` ${items?.is_read ? "" : "bg-light"}`}>{items?.email}</td>
                                                <td className={` ${items?.is_read ? "" : "bg-light"}`}>{items?.address}</td>
                                                <td className={` ${items?.is_read ? "" : "bg-light"}`}>{items?.orderStatus}</td>
                                                <td className={` ${items?.is_read ? "" : "bg-light"}`}>{items?.subTotal}</td>
                                                <td className={` ${items?.is_read ? "" : "bg-light"} text-center`}>
                                                    {/* <Link to={`/view-product/${val._id}`}> */}
                                                    {/* <Link to={`/orders-detail/${items._id}`}>
                                                        <BsEye

                                                            className='text-muted me-3 cursor-pointer' size={26}
                                                        />
                                                    </Link> */}

                                                    <Link to={`/orders-detail/${items?._id}`} className='btn btn-edit me-1' >
                                                        <BsEye />
                                                    </Link>


                                                </td>
                                            </tr>

                                        </>
                                    )
                                    ))
                            }



                        </tbody>
                    </Table>
                </div>
            </div>
        </>
    )
}

export default AllOrdersScreen