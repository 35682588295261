import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addSubCategory } from '../../Services/variable';
import { deleteData, getData, postData, putData } from '../../Services/Api';
import { sweetAlert } from '../../lib/sweetalert';
import { getSubCategory } from '../../Redux/Slices/subCategorySlice';


const UseSubCategory = () => {

    const { loading, categoryList } = useSelector((state) => state.category)
    const handleCloseAddModal = () => setShowModal(false);
    const [showModal, setShowModal] = useState(false);
    const [mainCat, setMainCat] = useState("Select Category")
    const [mainCatId, setMainCatId] = useState()
    const [subCategory, setSubCategory] = useState()
    const [subCatId, setSubCatId] = useState()
    const [isLoading, setIsloading] = useState(false)


    const dispatch = useDispatch()
    const { subCategoryList } = useSelector((state) => state.subCategory)

    const [allData, setAllData] = useState([]);

    const handleShow = () => {
        setMainCat("Select Category")
        setMainCatId(null)
        setSubCatId(null)
        setSubCategory(null)
        setShowModal(true)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setIsloading(true)
        const obj = {
            main_category: mainCat,
            sub_category: subCategory,
            cat_id: mainCatId
        };

        if (subCatId) {

            putData(`${addSubCategory}/${subCatId}`, JSON.stringify(obj))
                .then((result) => {
                    setIsloading(false)
                    setShowModal(false)
                    dispatch(getSubCategory())
                    sweetAlert(result?.message, result?.status)

                })
        }
        else {

            postData(addSubCategory, JSON.stringify(obj))
                .then((result) => {
                    setIsloading(false)
                    setShowModal(false)
                    dispatch(getSubCategory())
                    sweetAlert(result?.message, result?.status)
                })
        }
    }

    const handleShowEditModal = (item) => {
        setSubCatId(item?._id)
        setMainCat(item?.main_category)
        setMainCatId(item?.cat_id)
        setSubCategory(item?.sub_category)
        setShowModal(true)
    }

    // const getSubCategory = () => {
    //     getData(addSubCategory)
    //         .then((data) => {
    //             setAllData(data?.data)
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }

    const handleDelete = async (id) => {
        setIsloading(true)
        deleteData(addSubCategory, id)
            .then((res) => {
                setIsloading(false)
                sweetAlert(res?.message, res?.status)
                dispatch(getSubCategory())
            })
            .catch((err) => {
                setIsloading(false)
                sweetAlert(err?.message, err?.status)
            })
    }


    useEffect(() => {
        dispatch(getSubCategory())
    }, [])


    return (
        [
            subCategoryList,
            categoryList,
            loading,
            handleCloseAddModal,
            showModal,
            setShowModal,
            mainCat,
            setMainCat,
            mainCatId,
            setMainCatId,
            subCategory,
            setSubCategory,
            subCatId,
            setSubCatId,
            allData,
            setAllData,
            handleShow,
            handleSubmit,
            handleShowEditModal,
            handleDelete,
            isLoading
        ]
    )
}

export default UseSubCategory