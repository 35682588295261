import React from 'react'
import ProductDetailScreen from '../Components/Products/ProductDetailScreen'
import ScrollToTop from '../Components/Main/ScrollToTop'

const ProductDetail = () => {
    return (
        <>
        <ScrollToTop /> 
            <ProductDetailScreen />
        </>
    )
}

export default ProductDetail