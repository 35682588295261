import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { postData, getRecord, putData, deleteData } from '../../Services/Api'
import { product } from '../../Services/variable'
import { sweetAlert } from '../../lib/sweetalert'
import { getProducts } from '../../Redux/Slices/productSlice'

const UseProduct = () => {

    const [id, setId] = useState()
    const [image, setImage] = useState()
    const [name, setName] = useState("")
    const [price, setPrice] = useState("")
    const [brand, setBrand] = useState("Select Brand")
    const [brandId, setBrandId] = useState("")
    const [category, setCategory] = useState("Select Category")
    const [categoryId, setCategoryId] = useState("")
    const [subCat, setSubCat] = useState("Select Sub Category")
    const [subCatId, setSubCatId] = useState("")
    const [description, setDescription] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [delLoading, setDelLoading] = useState(false)


    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { categoryList } = useSelector((state) => state.category)
    const { subCategoryList } = useSelector((state) => state.subCategory)
    const { brandList } = useSelector((state) => state.brand)
    const { products } = useSelector((state) => state.product)


    const { slug } = useParams()

    const handleAddProducts = (e) => {
        e.preventDefault();
        setIsLoading(true)

        const form = new FormData();
        form.append("title", name);
        form.append("price", price);
        form.append("brand", brand);
        form.append("brand_id", brandId);
        form.append("category", category);
        form.append("category_id", categoryId);
        form.append("sub_category", subCat);
        form.append("sub_categoryId", subCatId);
        form.append("description", description);
        form.append("image", image);


        // Check if a new image is selected
        // if (image instanceof File) {
        //     form.append("image", image);
        // }

        if (slug) {

            const form = new FormData();
            form.append("image", image);
            form.append("title", name);
            form.append("price", price);
            form.append("brand", brand);
            form.append("brand_id", brandId);
            form.append("category", category);
            form.append("category_id", categoryId);
            form.append("sub_category", subCat);
            form.append("sub_categoryId", subCatId);
            form.append("description", description);


            putData(`${product}/${slug}`, form, false)
                .then((data) => {
                    setIsLoading(false)
                    sweetAlert(data?.message, data?.status);
                    navigate("/all-products");
                })
                .catch((err) => {
                    console.error("Update Error:", err);
                    sweetAlert("Failed to update product", "error");
                });
        } else {

            const form = new FormData();
            form.append("title", name);
            form.append("price", price);
            form.append("brand", brand);
            form.append("brand_id", brandId);
            form.append("category", category);
            form.append("category_id", categoryId);
            form.append("sub_category", subCat);
            form.append("sub_categoryId", subCatId);
            form.append("description", description);
            form.append("image", image)

            postData(product, form, false)
                .then((data) => {
                    setIsLoading(false)
                    sweetAlert(data?.message, data?.status);
                    navigate("/all-products");
                })
                .catch((err) => {
                    console.error("Add Error:", err);
                    sweetAlert("Failed to add product", "error");
                });
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const getProduct = (slug) => {
        getRecord(product, slug)
            .then((data) => {

                setId(data?.data?._id)
                setImage(data?.data?.image)
                setName(data?.data?.title)
                setPrice(data?.data?.price)
                setBrand(data?.data?.brand)
                setBrandId(data?.data?.brand_id)
                setCategory(data?.data?.category)
                setCategoryId(data?.data?.category_id)
                setSubCat(data?.data?.sub_category)
                setSubCatId(data?.data?.sub_categoryId)
                setDescription(data?.data?.description)

            })
            .catch((err) => {
                console.log("err", err)
            })

    }

    useEffect(() => {
        if (slug) {
            getProduct(slug)
        }
    }, [])


    useEffect(() => {
        dispatch(getProducts())
    }, [])

    const handleDelete = (id) => {
        setDelLoading(true)
        deleteData(product, id)
            .then((data) => {
                setDelLoading(false)
                sweetAlert(data?.message, data?.status)
                dispatch(getProducts())
            })
            .catch((err) => {
                sweetAlert(err?.message, err?.status)
            })
    }


    return (
        [
            products,
            slug,
            categoryList,
            subCategoryList,
            brandList,
            id,
            setId,
            image,
            setImage,
            name,
            setName,
            price,
            setPrice,
            brand,
            setBrand,
            brandId,
            setBrandId,
            category,
            setCategory,
            categoryId,
            setCategoryId,
            subCat,
            setSubCat,
            subCatId,
            setSubCatId,
            description,
            setDescription,
            handleAddProducts,
            getProduct,
            handleDelete,
            handleFileChange,
            isLoading,
            delLoading
        ]
    )
}

export default UseProduct